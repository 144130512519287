import React, { useCallback, useEffect, useRef, useState } from "react";
import icon_camera from "./assets/camera.svg";
import icon_plus from "./assets/plus.svg";
import icon_minus from "./assets/minus.svg";
import icon_back from "./assets/arrowCounterClockwise.svg";

// Funzione di supporto per scaricare il file base64 come immagine
function downloadBase64File(contentBase64, fileName) {
  const downloadLink = document.createElement("a");
  downloadLink.href = contentBase64;
  downloadLink.target = "_self";
  downloadLink.download = fileName;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

const PlannerPlayer = ({ onLoad, iframeRef }) => {
  const emersyaIframeRef = useRef(null);
  const [floorMaterial, setFloorMaterial] = useState("piano");
  const [emersyaViewerInstance, setEmersyaViewerInstance] = useState(null);

  // Elenco dei materiali disponibili per il pavimento
  const floorMaterials = [
    "piano",
    "resina",
    "legno",
    "floor a",
    "floor b",
    "floor c",
  ];

  useEffect(() => {
    // Check if the script is already loaded
    if (!document.getElementById("emersya")) {
      // Carichiamo lo script di Emersya
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://cdn.emersya.com/f/emersyaLoader.js";
      script.id = "emersya";
      document.head.appendChild(script);
    }

    // Handler per l'inizializzazione del viewer
    const handleViewerInitialized = (event) => {
      console.log("Emersya viewer initialization event received");

      // Check if the event is for our iframe
      if (window.emViewers && window.emViewers["emersyaIframe"]) {
        console.log("Emersya viewer initialized successfully");
        setEmersyaViewerInstance(window.emViewers["emersyaIframe"]);
      }
    };

    // Listener per l'evento di inizializzazione
    document.addEventListener(
      "emersyaViewerInitialized",
      handleViewerInitialized,
      false
    );

    // Check if the viewer is already available
    if (window.emViewers && window.emViewers["emersyaIframe"]) {
      console.log("Emersya viewer already available on component mount");
      setEmersyaViewerInstance(window.emViewers["emersyaIframe"]);
    }

    // Poll for viewer existence for a short time
    const checkInterval = setInterval(() => {
      if (window.emViewers && window.emViewers["emersyaIframe"]) {
        console.log("Emersya viewer found through polling");
        setEmersyaViewerInstance(window.emViewers["emersyaIframe"]);
        clearInterval(checkInterval);
      }
    }, 1000);

    // Stop polling after 10 seconds
    setTimeout(() => clearInterval(checkInterval), 10000);

    return () => {
      const script = document.getElementById("emersya");
      if (script && script.parentNode) {
        script.parentNode.removeChild(script);
      }
      document.removeEventListener(
        "emersyaViewerInitialized",
        handleViewerInitialized
      );
      clearInterval(checkInterval);
    };
  }, []);

  // Cambio materiale pavimento
  const toggleFloorMaterial = useCallback(() => {
    if (!iframeRef.current) return;

    const currentIndex = floorMaterials.indexOf(floorMaterial);
    const nextIndex = (currentIndex + 1) % floorMaterials.length;
    const nextMaterial = floorMaterials[nextIndex];

    iframeRef.current.contentWindow.postMessage(
      {
        action: "setMaterials",
        materials: [
          {
            materialVariation: nextMaterial,
            configurableMaterial: "DefaultMaterial",
          },
        ],
      },
      "*"
    );

    setFloorMaterial(nextMaterial);
    console.log(nextMaterial);
  }, [floorMaterial, floorMaterials, iframeRef]);

  // Zoom in
  const zoomIn = useCallback(() => {
    if (!iframeRef.current) return;
    iframeRef.current.contentWindow.postMessage(
      {
        action: "startZoomIn",
      },
      "*"
    );

    setTimeout(() => {
      iframeRef.current.contentWindow.postMessage(
        {
          action: "stopZoomIn",
        },
        "*"
      );
    }, 800);
  }, [iframeRef]);

  // Zoom out
  const zoomOut = useCallback(() => {
    if (!iframeRef.current) return;
    iframeRef.current.contentWindow.postMessage(
      {
        action: "startZoomOut",
      },
      "*"
    );

    setTimeout(() => {
      iframeRef.current.contentWindow.postMessage(
        {
          action: "stopZoomOut",
        },
        "*"
      );
    }, 600);
  }, [iframeRef]);

  // Reset camera
  const resetCamera = useCallback(() => {
    if (!iframeRef.current) return;
    iframeRef.current.contentWindow.postMessage(
      {
        action: "resetCamera",
        params: {},
      },
      "*"
    );

    // Se preferisci non avviare la rotazione, puoi rimuovere il "play":
    iframeRef.current.contentWindow.postMessage(
      {
        action: "play",
      },
      "*"
    );
  }, [iframeRef]);

  // Funzione getScreenshot migliorata con camera dinamica
  const getScreenshot = useCallback(() => {
    console.log("Taking screenshot with dynamic camera view");

    // Funzione di supporto per scaricare il file base64 come immagine
    function downloadBase64File(contentBase64, fileName) {
      const downloadLink = document.createElement("a");
      downloadLink.href = contentBase64;
      downloadLink.target = "_self";
      downloadLink.download = fileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }

    // Verifica che l'iframe esista
    if (!iframeRef.current || !iframeRef.current.contentWindow) {
      console.error("Iframe reference not available");
      return;
    }

    // Prima otteniamo i dati della camera, poi facciamo lo screenshot
    let hasProcessedScreenshot = false;

    // Handler per entrambe le risposte (camera e screenshot)
    const messageHandler = (event) => {
      const data = event.data;
      console.log("Received message:", data?.action);

      // Se riceviamo i dati della camera
      if (data && data.action === "onGetCamera" && data.camera) {
        console.log("Camera data received:", data.camera);

        // Ora possiamo richiedere lo screenshot con i dati della camera
        iframeRef.current.contentWindow.postMessage(
          {
            action: "getScreenshot",
            width: 512,
            height: 288,
            transparentBackground: false,
            camera: {
              position: [
                data.camera.position[0],
                data.camera.position[1],
                data.camera.position[2],
              ],
              target: [
                data.camera.target[0],
                data.camera.target[1],
                data.camera.target[2],
              ],
              up: [data.camera.up[0], data.camera.up[1], data.camera.up[2]],
            },
          },
          "*"
        );
      }

      // Se riceviamo lo screenshot
      if (
        data &&
        data.action === "onScreenshot" &&
        data.screenshot &&
        !hasProcessedScreenshot
      ) {
        hasProcessedScreenshot = true; // Previene doppi download
        console.log("Screenshot received!");

        // Crea nome file dinamico: Planner-YYYY-MM-DD-HHMMSS.png
        const now = new Date();
        const formattedDate = now
          .toISOString()
          .replace(/T/, "-")
          .replace(/\..+/, "")
          .replace(/:/g, "")
          .slice(0, 17);

        const fileName = `Planner-${formattedDate}.png`;

        // Scarica l'immagine
        downloadBase64File(data.screenshot, fileName);

        // Rimuovi il listener dopo aver ricevuto lo screenshot
        window.removeEventListener("message", messageHandler);
        console.log("Listener removed after screenshot processing");
      }
    };

    // Aggiungiamo il listener per gestire entrambe le risposte
    window.addEventListener("message", messageHandler);

    // Prima richiediamo i dati della camera
    iframeRef.current.contentWindow.postMessage(
      {
        action: "getCamera",
      },
      "*"
    );

    // Timeout di sicurezza
    setTimeout(() => {
      window.removeEventListener("message", messageHandler);
      console.log("Removed handler after timeout");
    }, 10000); // 10 secondi per dare più tempo al processo
  }, [iframeRef]);

  return (
    <div className="ay-relative ay-w-full ay-h-full">
      {/* Pannello comandi */}
      <div className="ay-z-10 ay-absolute ay-left-5 ay-bottom-5 ay-flex ay-flex-col ay-gap-3">
        {/* Bottone per cambio materiale - stile migliorato */}
        <button
          onClick={toggleFloorMaterial}
          className="ay-w-8 ay-h-8 ay-flex ay-items-center ay-justify-center"
          title={`Pavimento: ${floorMaterial}`}
        >
          <span className="ay-text-[8px] ay-font-bold ay-uppercase ay-leading-none ay-text-center">
            change
            <br />
            floor
          </span>
        </button>

        {/* Zoom in */}
        <img
          onClick={zoomIn}
          className="hover:ay-cursor-pointer"
          src={icon_plus}
          alt="Zoom In"
        />
        {/* Zoom out */}
        <img
          onClick={zoomOut}
          className="hover:ay-cursor-pointer"
          src={icon_minus}
          alt="Zoom Out"
        />
        {/* Reset camera */}
        <img
          onClick={resetCamera}
          className="hover:ay-cursor-pointer"
          src={icon_back}
          alt="Reset Camera"
        />
        {/* Screenshot */}
        <img
          onClick={getScreenshot}
          className="hover:ay-cursor-pointer"
          src={icon_camera}
          alt="Screenshot"
        />
      </div>

      {/* Iframe Emersya */}
      <iframe
        id="emersyaIframe"
        src="https://emersya.com/showcase/6EX6L8PMJE"
        className="ay-w-full ay-h-full ay-border-none ay-absolute ay-top-0 ay-left-0"
        ref={(el) => {
          emersyaIframeRef.current = el;
          if (iframeRef) iframeRef.current = el;
        }}
        onLoad={onLoad}
      />
    </div>
  );
};

export default PlannerPlayer;
