// src/Planner.jsx
import React, { useState, useEffect, useRef } from "react";
import FamilySelector from "./FamilySelector";
import logo from "./assets/logo-laCividina.png";

// Import the materials
import materiali from "./materials-stuff";
import wood from "./collections/wood-metal";
import placeholder from "./assets/placeholder.jpg"; // Placeholder image if needed

const angles = [0, 45, 90, 135, 180, 225, 270, 315];
const Y_OFFSET = 0.2; // Increased from 0.1 to 0.2 to avoid z-fighting

const Planner = () => {
  const iframeRef = useRef(null);
  const emptyImageRef = useRef(null);
  const nodeLocalIdsRef = useRef(1);

  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [families, setFamilies] = useState([]);
  const [selectedFamily, setSelectedFamily] = useState(null);
  const [productNodes, setProductNodes] = useState({});
  const [productNodesToAdd, setProductNodesToAdd] = useState({});
  const [ignoreClick, setIgnoreClick] = useState(false);
  const [accordionState, setAccordionState] = useState({});
  const [isAccordionHidden, setIsAccordionHidden] = useState(false);
  const [materialsAccordionState, setMaterialsAccordionState] = useState({});
  const [
    materialCollectionsAccordionState,
    setMaterialCollectionsAccordionState,
  ] = useState({});
  const [
    configurableMaterialAccordionState,
    setConfigurableMaterialAccordionState,
  ] = useState({});

  const [floorDimensions, setFloorDimensions] = useState({
    length: 8.0,
    width: 8.0,
    height: 1.0,
  });

  // Function to get materials for a given configurable material
  const getMaterialsForConfigurableMaterial = (
    configurableMaterialName,
    materialVariations
  ) => {
    let materialsList = [];
    if (configurableMaterialName === "imbottito") {
      materialsList = materiali;
    } else if (
      configurableMaterialName === "struttura" ||
      configurableMaterialName === "piano" ||
      configurableMaterialName === "finitura"
    ) {
      materialsList = wood;
    } else {
      materialsList = []; // Handle other cases if necessary
    }

    // Create a set of names from materialVariations for quick lookup
    const variationNamesSet = new Set(
      materialVariations.map((variation) => variation.name)
    );

    // Filter materialsList to include only matching materials
    const filteredMaterialsList = materialsList
      .map((materialCategory) => {
        const filteredItems = materialCategory.items.filter((item) =>
          variationNamesSet.has(item.name)
        );
        return {
          ...materialCategory,
          items: filteredItems,
        };
      })
      .filter((category) => category.items.length > 0); // Remove categories with no items

    return filteredMaterialsList;
  };

  // Initialize transparent image for drag
  useEffect(() => {
    const emptyImage = new Image();
    emptyImage.src =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4QYHDTQ15hoLGQAAAB1pVFh0Q29tbWVudAAAAAAAQ3JlYXRlZCB3aXRoIEdJTVBkLmUHAAAAC0lEQVQI12NgAAIAAAUAAeImBZsAAAAASUVORK5CYII=";
    emptyImageRef.current = emptyImage;
  }, []);

  // Configure listener for messages from iframe
  useEffect(() => {
    const handleMessage = (event) => {
      const data = event.data;
      if (!data) return;

      switch (data.action) {
        case "onError":
          handleError(data);
          break;
        case "onSuccess":
          handleSuccess(data);
          break;
        case "onProductNodeInstanceConfigurableMaterialsGet":
          handleConfigurableMaterials(data);
          break;
        case "onCollisionsUpdate":
          handleCollisionsUpdate(data);
          break;
        case "onMeshSelection":
          handleMeshSelection(data);
          break;
        case "onStateChange":
          handleStateChange(data);
          break;
        default:
        /*   console.log("Unknown action:", data); */
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [productNodesToAdd, productNodes]);

  // Send "registerCallback" when iframe is loaded
  const handleIframeLoad = () => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow.postMessage(
        { action: "registerCallback" },
        "*"
      );
    }
  };

  // Handle errors received from iframe
  const handleError = (data) => {
    const { callAction, id, message } = data;
    console.error(`Error in ${callAction}:`, message);
    if (
      [
        "startProductNodeDrop",
        "addProductNodeInstance",
        "addProductNodesInstances",
      ].includes(callAction)
    ) {
      setProductNodesToAdd((prev) => {
        const updated = { ...prev };
        delete updated[id];
        return updated;
      });
    }
  };

  // Handle successes received from iframe
  const handleSuccess = (data) => {
    const { callAction, id } = data;
    const responseData = data.data;
    switch (callAction) {
      case "startProductNodeDrop":
      case "addProductNodeInstance":
      case "addProductNodesInstances":
        if (productNodesToAdd[id]) {
          const pn = productNodesToAdd[id];
          setProductNodes((prev) => ({
            ...prev,
            [pn.localId]: {
              ...pn,
              position: [pn.position[0], pn.position[1] + 0.1, pn.position[2]], // Add Y offset
            },
          }));
          setProductNodesToAdd((prev) => {
            const updated = { ...prev };
            delete updated[id];
            return updated;
          });
          // Request to get configurable materials
          iframeRef.current.contentWindow.postMessage(
            {
              action: "getProductNodeInstanceConfigurableMaterials",
              localId: pn.localId,
            },
            "*"
          );
        }
        break;
      case "getPlannerUsableProjects":
        setCategories(responseData);
        // Resize floor with default values
        resizeFloor();
        setLoading(false);
        break;
      case "removeProductNodeInstance":
        setProductNodes((prev) => {
          const updated = { ...prev };
          delete updated[data.id];
          return updated;
        });
        break;
      default:
        console.log("Unhandled success action:", callAction);
    }
  };

  // Handle configurable materials received from iframe
  const handleConfigurableMaterials = (data) => {
    const { localId, configurableMaterials } = data;
    setProductNodes((prev) => ({
      ...prev,
      [localId]: {
        ...prev[localId],
        configurableMaterials,
      },
    }));
  };

  // Handle collision updates received from iframe
  const handleCollisionsUpdate = (data) => {
    const { localIds } = data;
    setProductNodes((prev) => {
      const updated = { ...prev };
      Object.keys(updated).forEach((localId) => {
        if (localIds.includes(parseInt(localId))) {
          updated[localId].colliding = true;
        } else {
          updated[localId].colliding = false;
        }
      });
      return updated;
    });

    // Inform iframe which nodes should be rendered as ghosts
    const nodesToUpdate = Object.keys(productNodes).map((localId) => ({
      localId: parseInt(localId),
      renderAsGhost: productNodes[localId].colliding,
    }));

    iframeRef.current.contentWindow.postMessage(
      {
        action: "setProductNodesToGhostRenderingMode",
        nodes: nodesToUpdate,
      },
      "*"
    );
  };

  // Handle mesh selection from iframe
  const handleMeshSelection = (data) => {
    const { nodeLocalId } = data;
    if (productNodes[nodeLocalId]) {
      selectProductNodeInstance(productNodes[nodeLocalId])();
    }
  };

  // Handle state changes from iframe
  const handleStateChange = (data) => {
    const viewerState = data.state?.viewerState;
    if (viewerState === "loaded") {
      iframeRef.current.contentWindow.postMessage(
        {
          action: "getPlannerUsableProjects",
        },
        "*"
      );
    }
  };

  // Function to resize the floor
  const resizeFloor = () => {
    const { length, width, height } = floorDimensions;

    iframeRef.current.contentWindow.postMessage(
      {
        action: "resizePlannerSpace",
        length,
        width,
        height,
      },
      "*"
    );
  };

  // Function to handle the start of dragging a product
  const onDragStart = (productNode) => (event) => {
    setIgnoreClick(true);
    const localId = nodeLocalIdsRef.current + 1;
    nodeLocalIdsRef.current += 1;

    const nodesToAdd = [
      {
        SKU: productNode.SKU,
        projectCode: productNode.projectCode,
        localId,
        parentLocalId: 1,
        position: [0, 0.1, 0],
        rotationAxis: [0, 1, 0],
        rotationAngle: 0,
      },
    ];

    iframeRef.current.contentWindow.postMessage(
      {
        action: "startProductNodeDrop",
        id: localId,
        nodesToAdd,
      },
      "*"
    );

    setProductNodesToAdd((prev) => ({
      ...prev,
      [localId]: {
        SKU: productNode.SKU,
        projectCode: productNode.projectCode,
        localId,
        parentLocalId: 1,
        refData: productNode,
        position: [0, 0.1, 0],
        rotationAngle: 0,
        configurableMaterials: [],
      },
    }));

    // Set drag image to transparent
    event.dataTransfer.setDragImage(emptyImageRef.current, 0, 0);
    event.preventDefault();
    event.stopPropagation();
  };

  // Function to handle clicking on a product
  const onClick = (productNode) => (event) => {
    if (ignoreClick) {
      setIgnoreClick(false);
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    const localId = nodeLocalIdsRef.current + 1;
    nodeLocalIdsRef.current += 1;

    const nodes = [
      {
        SKU: productNode.SKU,
        projectCode: productNode.projectCode,
        localId,
        parentLocalId: 1,
        draggable: true,
        tracked: true,
        position: [0, 0.1, 0],
        rotationAxis: [0, 1, 0],
        rotationAngle: 0,
        configurableMaterials: [],
      },
    ];

    iframeRef.current.contentWindow.postMessage(
      {
        action: "addProductNodesInstances",
        id: localId,
        nodes,
      },
      "*"
    );

    setProductNodesToAdd((prev) => ({
      ...prev,
      [localId]: {
        SKU: productNode.SKU,
        projectCode: productNode.projectCode,
        localId,
        parentLocalId: 1,
        refData: productNode,
        position: [0, 0, 0],
        rotationAxis: [0, 1, 0],
        rotationAngle: 0,
        height: 1, // Default height scale
      },
    }));
  };

  // Function to remove an added product
  const removeProductNodeInstance = (pn) => (event) => {
    event.stopPropagation();
    event.preventDefault();
    iframeRef.current.contentWindow.postMessage(
      {
        action: "removeProductNodeInstance",
        localId: pn.localId,
        id: pn.localId,
      },
      "*"
    );
  };

  // Function to rotate an added product
  const rotateProductNodeInstance = (pn) => (event) => {
    event.stopPropagation();
    event.preventDefault();
    const angleDeg = parseInt(event.target.value);
    const angleRad = (angleDeg * Math.PI) / 180;

    setProductNodes((prev) => ({
      ...prev,
      [pn.localId]: {
        ...prev[pn.localId],
        rotationAngle: angleRad,
      },
    }));

    iframeRef.current.contentWindow.postMessage(
      {
        action: "setProductNodesInstancesTransformations",
        nodes: [
          {
            localId: pn.localId,
            rotationAxis: [0, 1, 0],
            rotationAngle: angleRad,
          },
        ],
      },
      "*"
    );
  };

  // Function to change the height of an added product
  const changeProductNodeInstanceHeight = (pn) => (event) => {
    event.stopPropagation();
    event.preventDefault();
    const newHeight = parseInt(event.target.value) / 100.0;

    setProductNodes((prev) => ({
      ...prev,
      [pn.localId]: {
        ...prev[pn.localId],
        height: newHeight,
        position: [
          prev[pn.localId].position[0],
          Y_OFFSET + newHeight,
          prev[pn.localId].position[2],
        ],
      },
    }));

    iframeRef.current.contentWindow.postMessage(
      {
        action: "setProductNodesInstancesTransformations",
        nodes: [
          {
            localId: pn.localId,
            position: [pn.position[0], Y_OFFSET + newHeight, pn.position[2]],
          },
        ],
      },
      "*"
    );
  };

  // Function to select an added product
  const selectProductNodeInstance = (pn) => () => {
    iframeRef.current.contentWindow.postMessage(
      {
        action: "selectProductNode",
        localId: pn.localId,
      },
      "*"
    );
  };

  // Function to apply material to an added product
  const applyMaterial =
    (pn, configurableMaterial, materialVariation) => (event) => {
      if (event) event.stopPropagation();
      iframeRef.current.contentWindow.postMessage(
        {
          action: "setMaterials",
          materials: [
            {
              configurableMaterial,
              materialVariation,
              nodeLocalIds: [pn.localId],
            },
          ],
        },
        "*"
      );
    };

  // Function to toggle the state of an accordion
  const toggleAccordionSection = (sectionId) => () => {
    setAccordionState((prev) => ({
      ...prev,
      [sectionId]: !prev[sectionId],
    }));
  };

  // Toggle materials accordion for a product
  const toggleMaterialsAccordion = (localId) => () => {
    setMaterialsAccordionState((prev) => ({
      ...prev,
      [localId]: !prev[localId],
    }));
  };

  // Toggle configurableMaterial accordion
  const toggleConfigurableMaterialAccordion =
    (localId, configurableMaterialName) => () => {
      setConfigurableMaterialAccordionState((prev) => ({
        ...prev,
        [`${localId}-${configurableMaterialName}`]:
          !prev[`${localId}-${configurableMaterialName}`],
      }));
    };

  // Add listener for Shift key to toggle snap mode
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Shift") {
        iframeRef.current.contentWindow.postMessage(
          {
            action: "togglePlannerSnapMode",
            value: true,
          },
          "*"
        );
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === "Shift") {
        iframeRef.current.contentWindow.postMessage(
          {
            action: "togglePlannerSnapMode",
            value: false,
          },
          "*"
        );
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  // Process categories to extract products and families
  useEffect(() => {
    if (categories.length > 0) {
      const productsList = [];
      const familiesSet = new Set();
      categories.forEach((category) => {
        category.projects.forEach((project) => {
          project.productNodes.forEach((productNode) => {
            const productFamily = project.familyName || category.name || "";
            productsList.push({
              ...productNode,
              projectCode: project.code,
              family: productFamily,
              category: category.name,
            });
            familiesSet.add(productFamily);
          });
        });
      });
      setProducts(productsList);
      setFamilies(Array.from(familiesSet));
    }
  }, [categories]);

  const handleFamilyChange = (newFamilyName) => {
    setSelectedFamily(newFamilyName);
  };

  const toggleAccordionVisibility = () => {
    setIsAccordionHidden((prevState) => !prevState);
    setTimeout(() => {
      iframeRef.current.contentWindow.postMessage(
        {
          action: "resizePlannerSpace",
        },
        "*"
      );
    }, 150);
  };

  // Group products by category
  const groupedProducts = products.reduce((acc, product) => {
    const categoryName = product.category || "Uncategorized";
    if (!acc[categoryName]) {
      acc[categoryName] = [];
    }
    acc[categoryName].push(product);
    return acc;
  }, {});

  // Filtered grouped products based on selected family
  const filteredGroupedProducts = {};

  Object.keys(groupedProducts).forEach((categoryName) => {
    const filteredProducts = groupedProducts[categoryName].filter((product) => {
      if (!selectedFamily) return true;
      return product.family === selectedFamily;
    });
    if (filteredProducts.length > 0) {
      filteredGroupedProducts[categoryName] = filteredProducts;
    }
  });

  // CollectionItem component
  const CollectionItem = ({
    material,
    isSelected,
    onToggle,
    isOpen,
    onMaterialChange,
    customConfig,
  }) => {
    return (
      <div
        className={`${
          customConfig ? "ay-col-span-1" : "ay-col-span-2"
        } ay-flex ay-flex-col`}
      >
        {customConfig ? (
          <div className="">
            {customConfig.customMaterial.map((customItemSlug) => {
              const matchingMaterial = material.items.find(
                (item) => item.slug === customItemSlug
              );

              return matchingMaterial ? (
                <div
                  key={customItemSlug}
                  className={`material-item ay-group ay-relative ${
                    isSelected === matchingMaterial ? "ay-active" : ""
                  }`}
                  onClick={(event) => onMaterialChange(matchingMaterial, event)}
                >
                  <div className="ay-overflow-hidden ay-rounded-lg hover:ay-border-gray-900 hover:ay-border-2">
                    <img
                      className="ay-aspect-square ay-w-full ay-rounded-lg ay-object-cover group-hover:ay-border ay-border-black ay-scale-125 group-hover:ay-scale-200 ay-transition-transform"
                      src={matchingMaterial.thumbnail || placeholder}
                      alt={matchingMaterial.name}
                      style={{ transformOrigin: "center center" }}
                    />
                  </div>
                  <p className="ay-text-sm ay-mt-3.5">
                    {matchingMaterial.name}
                  </p>
                </div>
              ) : null;
            })}
          </div>
        ) : (
          <div>
            <div onClick={() => onToggle(material.collection)}>
              <div className="ay-border-b ay-border-gray-300 w-full ay-py-1.5">
                <h2
                  className={`ay-text-md ay-flex ay-justify-between ${
                    isOpen ? "ay-font-bold" : ""
                  }`}
                >
                  {material.collection.toUpperCase()}
                  <span>{isOpen ? "-" : "+"}</span>
                </h2>
              </div>
            </div>
            {isOpen && (
              <div className="ay-category-items-grid ay-grid xl:ay-grid-cols-4 lg:ay-grid-cols-4 md:ay-grid-cols-4 ay-grid-cols-2 ay-gap-4 ay-mt-2.5">
                {material.items.map((item) => (
                  <div
                    key={item.slug}
                    className={`ay-material-item ay-group ay-relative ${
                      isSelected === item ? "ay-active" : ""
                    }`}
                    onClick={(event) => onMaterialChange(item, event)}
                  >
                    <div className="ay-overflow-hidden ay-rounded-lg hover:ay-border-gray-900 hover:ay-border-2">
                      <img
                        className="ay-aspect-square ay-w-full ay-rounded-lg ay-object-cover ay-group-hover:ay-border  ay-scale-125 group-hover:ay-scale-200 ay-transition-transform"
                        src={item.thumbnail || placeholder}
                        alt={item.name}
                        style={{ transformOrigin: "center center" }}
                      />
                    </div>
                    <p className="ay-text-sm ay-mt-3.5">{item.name}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {loading && (
        <div
          className="ay-w-full ay-h-screen ay-bg-white ay-opacity-95 ay-fixed ay-top-0 ay-z-50 ay-flex ay-justify-center ay-items-center ay-text-3xl"
          id="loader"
        >
          <img
            src={logo}
            alt="Logo laCividina"
            className="ay-animate-scale-pulse"
          />
        </div>
      )}
      <div className="ay-flex ay-flex-col md:ay-flex-row ay-h-screen ay-overflow-hidden">
        {/* Iframe Container */}
        <div
          className={`ay-relative ${
            isAccordionHidden ? "ay-w-full" : "ay-w-full md:ay-w-4/5"
          } ay-transition-all duration-500 ay-ease-in-out`}
        >
          <iframe
            id="emersyaIframe"
            src="https://emersya.com/showcase/6EX6L8PMJE"
            className="ay-w-full ay-h-full ay-border-none ay-absolute ay-top-0 ay-left-0"
            ref={iframeRef}
            onLoad={handleIframeLoad}
          ></iframe>

          <button
            onClick={toggleAccordionVisibility}
            className="ay-absolute ay-right-4 ay-opacity-20 ay-top-1/2 ay-transform ay--translate-y-1/2 ay-text-3xl ay-font-bold ay-focus:ay-outline-none"
          >
            {isAccordionHidden ? "<" : ">"}
          </button>
        </div>

        {/* UI Container */}
        <div
          className={`ay-flex ay-flex-col ay-p-5 ay-bg-[#f9f9f9] ay-overflow-auto ${
            isAccordionHidden ? "ay-w-0" : "ay-w-full md:ay-w-3/5 lg:ay-w-2/6"
          } ay-transition-all duration-500 ay-ease-in-out`}
          style={{ display: isAccordionHidden ? "none" : "block" }}
        >
          {/* Floor Resizing Controls */}
          <div className="ay-mb-6 ay-flex ay-flex-row ay-gap-2">
            <div className="ay-flex ay-items-center ay-mb-2">
              <label
                htmlFor="lengthInput"
                className="ay-w-auto ay-text-right ay-mr-2"
              >
                L
              </label>
              <input
                type="number"
                id="lengthInput"
                defaultValue={3.0}
                min={1.0}
                max={10.0}
                step={0.5}
                onChange={(e) =>
                  setFloorDimensions((prev) => ({
                    ...prev,
                    length: parseFloat(e.target.value),
                  }))
                }
                onBlur={resizeFloor}
                className="ay-w-20 ay-p-1 ay-border ay-border-gray-300 ay-rounded"
              />
            </div>
            <div className="ay-flex ay-items-center ay-mb-2">
              <label
                htmlFor="widthInput"
                className="ay-w-auto ay-text-right ay-mr-2"
              >
                W
              </label>
              <input
                type="number"
                id="widthInput"
                defaultValue={3.0}
                min={1.0}
                max={10.0}
                step={0.5}
                onChange={(e) =>
                  setFloorDimensions((prev) => ({
                    ...prev,
                    width: parseFloat(e.target.value),
                  }))
                }
                onBlur={resizeFloor}
                className="ay-w-20 ay-p-1 ay-border ay-border-gray-300 ay-rounded"
              />
            </div>
            <div className="ay-flex ay-items-center ay-mb-2">
              <label
                htmlFor="heightInput"
                className="ay-w-auto ay-text-right ay-mr-2"
              >
                H
              </label>
              <input
                type="number"
                id="heightInput"
                defaultValue={1}
                min={1.0}
                max={10.0}
                step={0.5}
                onChange={(e) =>
                  setFloorDimensions((prev) => ({
                    ...prev,
                    height: parseFloat(e.target.value),
                  }))
                }
                onBlur={resizeFloor}
                className="ay-w-20 ay-p-1 ay-border ay-border-gray-300 ay-rounded"
              />
            </div>
          </div>

          {/* Added Products and Product List */}
          <div className="ay-flex-1 ay-overflow-auto">
            {/* Added Products List */}
            <div className="ay-mb-4">
              <h2 className="ay-text-xl ay-font-semibold ay-mb-2">
                Added Products
              </h2>
              {Object.values(productNodes).length > 0 ? (
                Object.values(productNodes).map((pn) => (
                  <div
                    key={pn.localId}
                    className={`ay-p-2 ay-border ay-border-gray-300 ay-rounded ay-relative ay-mb-4 ${
                      pn.colliding ? "ay-bg-red-200" : ""
                    }`}
                  >
                    <div
                      className="ay-flex ay-items-center ay-cursor-pointer"
                      onClick={selectProductNodeInstance(pn)}
                    >
                      <div
                        className="ay-w-24 ay-h-24 ay-bg-contain ay-bg-center ay-bg-no-repeat ay-mr-4"
                        style={{
                          backgroundImage: `url(${pn.refData.previewPath})`,
                        }}
                      ></div>
                      <span className="ay-flex-1 ay-truncate">
                        {pn.refData.name} ({pn.localId})
                      </span>

                      {/* Remove Button */}
                      <button
                        onClick={removeProductNodeInstance(pn)}
                        className="ay-absolute ay-top-0 ay-right-0 ay-mt-1 ay-mr-1 ay-bg-gray-500 ay-text-white ay-rounded-full ay-w-6 ay-h-6 ay-flex ay-items-center ay-justify-center ay-hover:bg-red-600"
                      >
                        ✕
                      </button>
                    </div>

                    <div className="ay-mt-2 ay-flex ay-items-center">
                      {/* Rotation Selector */}
                      <label className="ay-mr-2">Rotate:</label>
                      <select
                        onChange={rotateProductNodeInstance(pn)}
                        className="ay-p-1 ay-border ay-border-gray-300 ay-rounded"
                        value={
                          pn.rotationAngle
                            ? Math.round((pn.rotationAngle * 180) / Math.PI)
                            : 0
                        }
                        onClick={(e) => e.stopPropagation()}
                      >
                        {angles.map((angle) => (
                          <option key={angle} value={angle}>
                            {angle}°
                          </option>
                        ))}
                      </select>

                      {/* Height Input */}
                      <label className="ay-ml-4 ay-mr-2">Height (%):</label>
                      <input
                        type="number"
                        min="50"
                        max="150"
                        step="1"
                        value={pn.height ? pn.height * 100 : 100}
                        onChange={changeProductNodeInstanceHeight(pn)}
                        className="ay-p-1 ay-border ay-border-gray-300 ay-rounded ay-w-16"
                        onClick={(e) => e.stopPropagation()}
                      />
                    </div>

                    {/* Materials Accordion */}
                    {pn.configurableMaterials &&
                      pn.configurableMaterials.length > 0 && (
                        <div className="ay-mt-4">
                          <button
                            onClick={toggleMaterialsAccordion(pn.localId)}
                            className="ay-w-full ay-text-left ay-py-2 ay-px-4 ay-bg-gray-100 ay-rounded ay-focus:outline-none ay-flex ay-justify-between ay-items-center"
                          >
                            <span>Materials</span>
                            <span>
                              {materialsAccordionState[pn.localId] ? "-" : "+"}
                            </span>
                          </button>
                          {materialsAccordionState[pn.localId] && (
                            <div className="ay-mt-2">
                              {pn.configurableMaterials.map(
                                (configurableMaterial) => {
                                  const materialsList =
                                    getMaterialsForConfigurableMaterial(
                                      configurableMaterial.name,
                                      configurableMaterial.materialVariations
                                    );

                                  return (
                                    <div
                                      key={configurableMaterial.name}
                                      className="ay-mb-2"
                                    >
                                      {/* Configurable Material Accordion */}
                                      <button
                                        onClick={toggleConfigurableMaterialAccordion(
                                          pn.localId,
                                          configurableMaterial.name
                                        )}
                                        className="ay-w-full ay-text-left ay-py-2 ay-px-4 ay-bg-gray-200 ay-rounded ay-focus:outline-none ay-flex ay-justify-between ay-items-center"
                                      >
                                        <span>{configurableMaterial.name}</span>
                                        <span>
                                          {configurableMaterialAccordionState[
                                            `${pn.localId}-${configurableMaterial.name}`
                                          ]
                                            ? "-"
                                            : "+"}
                                        </span>
                                      </button>
                                      {configurableMaterialAccordionState[
                                        `${pn.localId}-${configurableMaterial.name}`
                                      ] && (
                                        <div className="ay-mt-2">
                                          {materialsList.map(
                                            (materialCategory, index) => (
                                              <CollectionItem
                                                key={index}
                                                material={materialCategory}
                                                isSelected={null}
                                                isOpen={
                                                  materialCollectionsAccordionState[
                                                    `${pn.localId}-${configurableMaterial.name}-${materialCategory.collection}`
                                                  ] || false
                                                }
                                                onToggle={(collectionName) =>
                                                  setMaterialCollectionsAccordionState(
                                                    (prev) => ({
                                                      ...prev,
                                                      [`${pn.localId}-${configurableMaterial.name}-${collectionName}`]:
                                                        !prev[
                                                          `${pn.localId}-${configurableMaterial.name}-${collectionName}`
                                                        ],
                                                    })
                                                  )
                                                }
                                                onMaterialChange={(
                                                  item,
                                                  event
                                                ) =>
                                                  applyMaterial(
                                                    pn,
                                                    configurableMaterial.name,
                                                    item.name
                                                  )(event)
                                                }
                                                customConfig={null}
                                              />
                                            )
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          )}
                        </div>
                      )}
                  </div>
                ))
              ) : (
                <p className="ay-text-gray-500">No products added yet.</p>
              )}
            </div>

            {/* FamilySelector and Product List */}
            <div className="ay-mb-4">
              <FamilySelector
                products={products}
                selectedFamily={selectedFamily}
                onFamilyChange={handleFamilyChange}
                onProductChange={() => {}}
              />

              <h2 className="ay-text-xl ay-font-semibold ay-mb-2">
                Product List
              </h2>
              {Object.keys(filteredGroupedProducts).length > 0 ? (
                Object.keys(filteredGroupedProducts).map((categoryName) => (
                  <div key={categoryName} className="ay-mb-2">
                    <button
                      onClick={toggleAccordionSection(categoryName)}
                      className="ay-w-full ay-text-left ay-py-2 ay-px-4 ay-bg-gray-100 ay-rounded ay-mb-1 ay-focus:outline-none ay-flex ay-justify-between ay-items-center"
                    >
                      <span>{categoryName}</span>
                      <span>{accordionState[categoryName] ? "-" : "+"}</span>
                    </button>
                    {accordionState[categoryName] && (
                      <div className="ay-pl-4">
                        {filteredGroupedProducts[categoryName].map(
                          (product) => (
                            <div
                              key={`${product.projectCode}-${product.SKU}`}
                              draggable
                              onDragStart={onDragStart(product)}
                              onClick={onClick(product)}
                              className="ay-flex ay-items-center ay-p-2 ay-mb-2 ay-border ay-border-gray-300 ay-rounded ay-cursor-pointer ay-hover:bg-gray-100"
                              onPointerDown={() => setIgnoreClick(false)}
                            >
                              <div
                                className="ay-w-16 ay-h-16 ay-bg-contain ay-bg-center ay-bg-no-repeat ay-mr-4"
                                style={{
                                  backgroundImage: `url(${product.previewPath})`,
                                }}
                              ></div>
                              <span className="ay-flex-1 ay-truncate">
                                {product.name}
                              </span>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <p className="ay-text-gray-500">
                  No products available for the selected family.
                </p>
              )}
            </div>
          </div>
          {/* Logo cividina */}
          <div className="ay-flex ay-justify-center ay-items-center ay-mt-4">
            <img
              src={logo}
              alt="Logo laCividina"
              className="ay-w-3/4 ay-object-contain"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Planner;
