import "./index.css";
import App from "./App.js";
import JsonEditorPage from "./JsonEditorPage";
import { createRoot } from "react-dom/client";
import Planner from "./Planner.js";

const root = createRoot(document.querySelector("#ayea-player"));

// Funzione per pulire gli script e le istanze di Emersya
const cleanupEmersya = () => {
  // Rimuoviamo gli script di Emersya
  const emersyaScripts = document.querySelectorAll('script[id^="emersya"]');
  emersyaScripts.forEach((script) => {
    if (script.parentNode) {
      script.parentNode.removeChild(script);
    }
  });

  // Pulisci le istanze globali di Emersya
  if (window.emViewers) {
    console.log("Pulizia istanze Emersya:", Object.keys(window.emViewers));
    Object.keys(window.emViewers).forEach((key) => {
      delete window.emViewers[key];
    });
  }

  // Rimuovi eventuali event listener globali
  if (window.emersyaCleanupListeners) {
    window.emersyaCleanupListeners.forEach((cleanup) => cleanup());
    window.emersyaCleanupListeners = [];
  }
};

const renderPage = () => {
  // Pulizia preventiva delle istanze Emersya
  cleanupEmersya();

  // Check for the hidden input with a mode attribute
  const modeInput = document.querySelector('input[type="hidden"][mode]');
  let mode = modeInput ? modeInput.getAttribute("mode") : null;

  // Use mode if set: "0" for normal, "1" for planner, "2" for JSON editor
  if (mode === "1") {
    console.log("Rendering Planner");
    root.render(<Planner />);
    return;
  } else if (mode === "2") {
    console.log("Rendering JsonEditorPage");
    root.render(<JsonEditorPage />);
    return;
  }

  // If mode is "0" or not specified, follow hash-based routing
  const hash = window.location.hash || "#/";
  if (hash === "#/json") {
    console.log("Rendering JsonEditorPage (hash)");
    root.render(<JsonEditorPage />);
  } else if (hash === "#/planner") {
    console.log("Rendering Planner (hash)");
    root.render(<Planner />);
  } else {
    console.log("Rendering App");
    const productId = document.getElementById("ayea-product-id").value;
    root.render(<App productId={productId} />);
  }
};

// Inizializziamo l'array per i cleanup listeners
if (!window.emersyaCleanupListeners) {
  window.emersyaCleanupListeners = [];
}

// Initial render
renderPage();

// Listen for hash changes
window.addEventListener("hashchange", renderPage);
