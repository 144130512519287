import './index.css';
import App from './App.js';
import JsonEditorPage from './JsonEditorPage'; // Importa il nuovo componente
import { createRoot } from 'react-dom/client';

const root = createRoot(document.querySelector('#ayea-player'));

const path = window.location.pathname;

if (path === '/json') {
  root.render(<JsonEditorPage />);
} else {
  const productId = document.getElementById('ayea-product-id').value;
  root.render(<App productId={productId} />);
}
