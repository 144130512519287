const products = [
  {
    name: "9031 tavolino / coffee table",
    family: "ACCURSIO",
    id: "9031",
    code: "U2WT3XFMGY",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "4SDO5662KB",
  },
  {
    name: "9030 tavolino / coffee table",
    family: "ACCURSIO",
    id: "9030",
    code: "YQG2HAR8HF",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "5WFKVQ4NH7",
  },
  {
    name: "9032 tavolino / coffee table",
    family: "ACCURSIO",
    id: "9032",
    code: "NY4X3CNL9J",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "BKT3RTMPVD",
  },
  {
    name: "9034 tavolino / coffee table",
    family: "ACCURSIO",
    id: "9034",
    code: "AQQYEHGG1O",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "DRBY3GW6TJ",
  },
  {
    name: "9039 consolle / console",
    family: "ACCURSIO",
    id: "9039",
    code: "MT21PKA6MM",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "PPNV4GYFBR",
  },
  {
    name: "9033 tavolino / coffee table",
    family: "ACCURSIO",
    id: "9033",
    code: "8RNXM4ET3H",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "",
  },
  {
    name: "9037 tavolino / coffee table ",
    family: "ACCURSIO",
    id: "9037",
    code: "ZI9388LIAK",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "YWZ3AGHB86",
  },
  {
    name: "9038 tavolino / coffee table",
    family: "ACCURSIO",
    id: "9038",
    code: "RC3JSFM376",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "GGV9JAYTFI",
  },
  {
    name: "9035 tavolino / coffee table",
    family: "ACCURSIO",
    id: "9035",
    code: "NPEE2191ZT",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "HQ7PH7M4IF",
  },
  {
    name: "9036 tavolino / coffee table",
    family: "ACCURSIO",
    id: "9036",
    code: "G3BXH6RIHZ",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "CRVP2C4RRZ",
  },
  {
    name: "9040 tavolino / coffee table",
    family: "ACCURSIO",
    id: "9040",
    code: "ZTRT9CRZFC",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "E39G9ROJ46",
  },
  {
    name: "9041 tavolino / coffee table",
    family: "ACCURSIO",
    id: "9041",
    code: "OYJQ5Z76TX",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "R0FQHSTD1N",
  },
  {
    name: "9045 tavolino / coffee table",
    family: "ACCURSIO",
    id: "9045",
    code: "1A3XT8DVUH",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "1HBWYJDXV6",
  },
  {
    name: "9042 tavolino / coffee table",
    family: "ACCURSIO",
    id: "9042",
    code: "OYJQ5Z76TX",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "R0FQHSTD1N",
  },
  {
    name: "9044 tavolino / coffee table",
    family: "ACCURSIO",
    id: "9044",
    code: "LDYKTWLMZK",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "CPK98L5L6D",
  },
  {
    name: "9043 tavolino / coffee table",
    family: "ACCURSIO",
    id: "9043",
    code: "85URGX0BBB",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "C8PYZCSTLJ",
  },
  {
    name: "1580 poltrona / armchair ",
    id: "1580",
    code: "5SBW34RHAC",
    family: "Agetti",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FAGGIO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "N722VPEQNV",
  },
  {
    name: " 1581 divano 2 posti / 2 seater sofa",
    id: "1581",
    code: "X8L02VUZO5",
    family: "Agetti",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FAGGIO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "6I07MU7GQE",
  },
  {
    name: "8886N_wood divano alto / high sofa",
    id: "8886N",
    code: "6BKD8HGGB0",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "OXBVMG7VO0",
  },
  {
    name: "8876N_steel divano alto / high sofa",
    id: "8876N",
    code: "H6XTFJW3S5",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "U4B31IO7JZ",
  },
  {
    name: "8903N_steel divano alto capitonné / high sofa capitonné",
    id: "8903N",
    code: "3J83QT095R",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "HTIWMBUSEO",
  },
  {
    name: "8908N_wood divano alto capitonné / high sofa capitonné",
    id: "8908N",
    code: "KKJU766X2H",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "ACLH0V741P",
  },
  {
    name: "8887N_wood divano alto con braccioli / high sofa with arms",
    id: "8887N",
    code: "M2Z26H56BX",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "PBH8E5WH38",
  },
  {
    name: "8877N_steel divano alto con braccioli / high sofa with arms",
    id: "8877N",
    code: "YVWJDA5LZ8",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "CB9237BQMJ",
  },
  {
    name: "8882N_wood divano basso / low sofa",
    id: "8882N",
    code: "AJWUKGUA3S",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "OUPS6RC58K",
  },
  {
    name: "8872N_steel divano basso / low sofa",
    id: "8872N",
    code: "VJ2813IWQO",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "7MRSL4VMMZ",
  },
  {
    name: "8906N_wood divano basso capitonné / low sofa capitonné",
    id: "8906N",
    code: "SK6GFVVUFN",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "03UKOORAEK",
  },
  {
    name: "8901N_steel divano basso capitonné / low sofa capitonné",
    id: "8901N",
    code: "5BONGXODB1",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "LI2MG9BER1",
  },
  {
    name: "8883N_wood divano basso con braccioli / low sofa with arms",
    id: "8883N",
    code: "WCNKBTCH8B",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "UJ7ZQN98V3",
  },
  {
    name: "8873N_steel divano basso con braccioli / low sofa with arms",
    id: "8873N",
    code: "P6TUZ5FLSP",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "FORLUB8H9K",
  },
  {
    name: "8884N_wood poltrona alta / high armchair",
    id: "8884N",
    code: "AJWUKGUA3S",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "OUPS6RC58K",
  },
  {
    name: "8874N_steel poltrona alta / high armchair",
    id: "8874N",
    code: "78ULN1CG20",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "XJIGTTDHOG",
  },
  {
    name: "8907N_wood poltrona alta capitonné / high armchair capitonné",
    id: "8907N",
    code: "7GS8O4GN2N",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "EAR3FA974E",
  },
  {
    name: "8902N_steel poltrona alta capitonné / high armchair capitonné",
    id: "8902N",
    code: "QS7BD3ZZSD",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "BL0AWRP6A7",
  },
  {
    name: "8885N_wood poltrona alta con braccioli / high armchair with arms",
    id: "8885N",
    code: "R6CD5OS0L6",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "IWNQB2TNCG",
  },
  {
    name: "8875N_steel poltrona alta con braccioli / high armchair with arms",
    id: "8875N",
    code: "0JZOS1BKGJ",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "X3CE0ESINP",
  },
  {
    name: "8880N_wood poltrona bassa / low armchair",
    id: "8880N",
    code: "TWJJR2BWL9",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "5RS2IAV8EV",
  },
  {
    name: "8870N_steel poltrona bassa / low armchair",
    id: "8870N",
    code: "CDLU0TNZIJ",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "XZ5J8BX7ET",
  },
  {
    name: "8905N_wood poltrona bassa capitonné / low armchair capitonné",
    id: "8905N",
    code: "LLC4V3Q65B",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "8AX0ZSNHEF",
  },
  {
    name: "8900N_steel poltrona bassa capitonné / low armchair capitonné",
    id: "8900N",
    code: "RT35GMM2XX",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "CWPPLVDQLF",
  },
  {
    name: "8881N_wood poltrona bassa con braccioli / low armchair with arms",
    id: "8881N",
    code: "MUV3DR5JMD",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "GGJFZ91P7P",
  },
  {
    name: "8871N_steel poltrona bassa con braccioli / low armchair with arms",
    id: "8871N",
    code: "WEYNCT2BN6",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "NEPQ8OP8GR",
  },
  {
    name: "8895N_wood poltrona small / small armchair",
    id: "8895N",
    code: "A8V0W86HJJ",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "KAGRTTN2ZT",
  },
  {
    name: "8890N_steel poltrona small / small armchair",
    id: "8890N",
    code: "ZHLBPGS4LZ",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "IUXAX6VS07",
  },
  {
    name: "8909N_wood poltrona small capitonné / small armchair capitonné",
    id: "8909N",
    code: "T1KN7NQPVH",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "P7BJSCMTD1",
  },
  {
    name: "8904N_steel poltrona small capitonné / small armchair capitonné",
    id: "8904N",
    code: "2OJPMKGAGX",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "SQKWIJRN2L",
  },
  {
    name: "8896N_wood poltrona small con braccioli / small armchair with arms",
    id: "8896N",
    code: "SCILD8IJB0",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "L2PD4NW8Q6",
  },
  {
    name: "8891N_steel poltrona small con braccioli / small armchair with arms",
    id: "8891N",
    code: "OLVLWHRLW7",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "DTNJODQ9JY",
  },
  {
    name: "8888N_wood pouf / ottoman",
    id: "8888N",
    code: "C389O2MI1G",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "D14FSX3VT0",
  },
  {
    name: "8878N_steel pouf / ottoman",
    id: "8878N",
    code: "J6H06L1508",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "BX70F63DFE",
  },
  {
    name: "1224NN poltrona / armchair",
    id: "1224NN",
    code: "EMYRI1B4XQ",
    family: "Anytime",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "FF6FQC0RBD",
  },
  {
    name: "1225NN divano 2 posti / 2 seater sofa",
    id: "1225NN",
    code: "R1PNLXYVGG",
    family: "Anytime",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "2FG5JSDPZF",
  },
  {
    name: "1226NN divano 2 posti major / 2 seater sofa major",
    id: "1226NN",
    code: "2ZX8SXRNML",
    family: "Anytime",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "4N4WLKNXHH",
  },
  {
    name: "1227NN divano 3 posti / 3 seater sofa",
    id: "1227NN",
    code: "BE62HVRLZ2",
    family: "Anytime",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "FUKIGATIE2",
  },
  {
    name: "1502NN pouf 90 / ottoman 90",
    id: "1502NN",
    code: "G7Q0VHGRMG",
    family: "Anytime",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "OFXCLGHBOQ",
  },
  {
    name: "1503NN panca 180 / bench 180",
    id: "1503NN",
    code: "Q1GBLL8DTA",
    family: "Anytime",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "2MIRUZQYXF",
  },
  {
    name: "1461 divano / sofa",
    id: "1461",
    code: "627ARN6T8R",
    hasFabric: true,
    customCollection: [
      "Stretchy",
      "Main Line Flax",
      "Bit",
      "Mosaic 2",
      "Uniform Mèlange",
      "Triangle",
      "Gentle 2",
      "Arda",
    ],
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    hasWood: true,
    family: "Apper",
    projectCode: "J65W9X4395",
  },
  {
    name: "1460 poltrona / armchair",
    id: "1460",
    code: "WS60HI4ATT",
    hasFabric: true,
    hasWood: true,
    customCollection: [
      "Stretchy",
      "Main Line Flax",
      "Bit",
      "Mosaic 2",
      "Uniform Mèlange",
      "Triangle",
      "Gentle 2",
      "Arda",
    ],
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    family: "Apper",
    projectCode: "1X2CXF8QE0",
  },
  {
    name: "8780 poltrona / armchair",
    id: "8780",
    code: "WU4V0XXVNY",
    hasFabric: true,
    hasWood: true,
    customCollection: ["Saddle Leather"],
    customMaterial: ["METAL RAL 9005"],
    family: "Aria",
    projectCode: "QYKFDMH3O0",
  },
  {
    name: "8785N poltroncina girevole / swivel small armchair",
    id: "8785N",
    code: "7UP2U0UZFL",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    family: "Bennet",
    projectCode: "IYSWTVP7LN",
  },
  {
    name: "8760N poltroncina / small armchair",
    id: "8760N",
    code: "YF5SVWJM36",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    family: "Bennet",
    projectCode: "G4IKPI8EE0",
  },
  {
    name: "1120 poltrona angolo small / armchair corner small",
    id: "1120",
    code: "WU2B0LFW5N",
    family: "Bernard",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "RF0TAL1NTV",
  },
  {
    name: "1121 poltrona centrale small / armchair central small",
    id: "1121",
    code: "5VXSK2B2HR",
    family: "Bernard",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "0E3QKPO9MU",
  },
  {
    name: "1122 poltrona small / armchair small",
    id: "1122",
    code: "97VFWH25ST",
    family: "Bernard",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "MAP78FUJYJ",
  },
  {
    name: "1125 pouf medium / ottoman medium",
    id: "1125",
    code: "LR4M3JDB26",
    family: "Bernard",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "0ZSQTYY2S1",
  },
  {
    name: "1130 poltrona / armchair",
    id: "1130",
    code: "IELL1C3OCN",
    family: "Bernard",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "8DBT0FTCE6",
  },
  {
    name: "1131 poltrona centrale / armchair central",
    id: "1131",
    code: "85KIKLVAHU",
    family: "Bernard",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "J3VNDWNMSI",
  },
  {
    name: "1132 divano 2 posti / 2 seater sofa",
    id: "1132",
    code: "NF3EGCMOHC",
    family: "Bernard",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "SMDBJHG2L2",
  },
  {
    name: "1133 divano 3 posti / 3 seater sofa",
    id: "1133",
    code: "T3LA92IGSN",
    family: "Bernard",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "PGLAQWZVHG",
  },
  {
    name: "1134 dormeuse / dormeuse",
    id: "1134",
    code: "WKKB4APGOG",
    family: "Bernard",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "YYJWY8YD66",
  },
  {
    name: "1135 pouf large / ottoman large",
    id: "1135",
    code: "PU2Q5GIQEN",
    family: "Bernard",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "BM4CO8J90R",
  },
  {
    name: "1136 panca / bench",
    id: "1136",
    code: "CTD9CTZWVB",
    family: "Bernard",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "N75ZOLLC5D",
  },
  {
    name: "1141 tavolino medium / coffee table medium",
    id: "1141",
    code: "7DQLPLZZF7",
    family: "Bernard",
    hasFabric: false,
    hasWood: true,
    hasPiano: true,
    customPiano: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "ROVERE SPAZZOLATO BIANCO",
      "WOOD RAL 9016",
      "WOOD RAL 9005",
      "MARMO MARQUINA",
      "MARMO CALACATTA",
      "MARMO CARRARA",
    ],
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "9GT2EOUL63",
  },
  {
    name: "1140 tavolino small",
    id: "1140",
    code: "D8J5E7RTXJ",
    family: "Bernard",
    hasFabric: false,
    hasPiano: true,
    hasWood: true,
    customPiano: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "ROVERE SPAZZOLATO BIANCO",
      "WOOD RAL 9016",
      "WOOD RAL 9005",
      "MARMO MARQUINA",
      "MARMO CALACATTA",
      "MARMO CARRARA",
    ],
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "UWX76Z81PT",
  },
  {
    name: "1124 pouf small / ottoman small",
    id: "1124",
    code: "G6SKERT9BQ",
    family: "Bernard",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "MO4XZOPXHP",
  },
  {
    name: "1430 tavolino / coffee table3",
    id: "1430",
    code: "76LNXOV7LP",
    family: "Borgo",
    hasFabric: false,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
      "METAL CIOCCOLATO",
      "METAL AZZURRO FIORDALISO",
      "METAL TE VERDE",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "GGOFXOM8CS",
  },
  {
    name: "1431 tavolino / coffee table",
    id: "1431",
    code: "CN5FOD4PZ6",
    family: "Borgo",
    hasFabric: false,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
      "METAL CIOCCOLATO",
      "METAL AZZURRO FIORDALISO",
      "METAL TE VERDE",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "8SMEQSC3JU",
  },
  {
    name: "1432 tavolino / coffee table",
    id: "1432",
    code: "Q40N3CL7PA",
    family: "Borgo",
    hasFabric: false,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
      "METAL CIOCCOLATO",
      "METAL AZZURRO FIORDALISO",
      "METAL TE VERDE",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "QFSORUOLXW",
  },
  {
    name: "1170 poltrona / armchair",
    id: "1170",
    code: "UI357RKW7W",
    family: "Couchette",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL RAL 9005", "METAL PITCH BLACK", "METAL RAL 9016"],
    projectCode: "VCNKDV806V",
  },
  {
    name: "1171 divano 2 posti / 2 seater sofa",
    id: "1171",
    code: "E3E2G1ELJV",
    family: "Couchette",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL RAL 9005", "METAL PITCH BLACK", "METAL RAL 9016"],
    projectCode: "UYNL0K1LYF",
  },
  {
    name: "1172 divano 3 posti / 3 seater sofa",
    id: "1172",
    code: "A9HL5LIX37",
    family: "Couchette",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL RAL 9005", "METAL PITCH BLACK", "METAL RAL 9016"],
    projectCode: "HYGMHTGFT9",
  },
  {
    name: "2102NN divano 2 posti / 2 seater sofa",
    id: "2102NN",
    code: "TJH4JNO635",
    family: "Cubus",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FAGGIO TINTA NOCE", "CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "4KATX9L7WM",
  },
  {
    name: "2103NN divano 3 posti / 3 seater sofa",
    id: "2103NN",
    code: "C4NAQAASUU",
    family: "Cubus",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FAGGIO TINTA NOCE", "CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "AKT26JPA4G",
  },
  {
    name: "2101NN poltrona / armchair",
    id: "2101NN",
    code: "30DTH0M6FZ",
    family: "Cubus",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FAGGIO TINTA NOCE", "CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "04Y8GEXKU1",
  },
  {
    name: "2104NN poltrona con ruote / armchair with castors",
    id: "2104NN",
    code: "H3JCXL2221",
    family: "Cubus",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FAGGIO TINTA NOCE", "CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "3J86FPT1N4",
  },
  {
    name: "8402 divano 2 posti small / small 2 seater sofa",
    id: "8402",
    code: "RZS850R4DR",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINO OPACO"],
    family: "Dep",
    projectCode: "M2LAMX834P",
  },
  {
    name: "8421 divano 2 posti / 2 seater sofa",
    id: "8421",
    code: "R42HJOVVUR",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINO OPACO"],
    family: "Dep",
    projectCode: "9DKWHSHX6S",
  },
  {
    name: "8422 divano 2 posti major / 2 seater sofa major",
    id: "8422",
    code: "5R8J5P57DH",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINO OPACO"],
    family: "Dep",
    projectCode: "CON5TZZVQC",
  },
  {
    name: "8423 divano 3 posti / 3 seater sofa",
    id: "8423",
    code: "XT6HWZX4OR",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINO OPACO"],
    family: "Dep",
    projectCode: "1ZZ3Y0XP9U",
  },
  {
    name: "8424 divano 3 posti major / 3 seater sofa major",
    id: "8424",
    code: "8DM8T5NKX7",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINO OPACO"],
    family: "Dep",
    projectCode: "K0AB7DSUA1",
  },
  {
    name: "8410 poltrona / armchair",
    id: "8410",
    code: "WR3994B9Z1",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINO OPACO"],
    family: "Dep",
    projectCode: "KYG16XHJ53",
  },
  {
    name: "8425 poltrona girevole / swivel armchair",
    id: "8425",
    code: "OSSNXE0181",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINO OPACO"],
    family: "Dep",
    projectCode: "FK7Y4M72EG",
  },
  {
    name: "8401 poltrona small / small armchair",
    id: "8401",
    code: "3TMUSGZ64O",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINO OPACO"],
    family: "Dep",
    projectCode: "GVVEQTWQOL",
  },
  {
    name: "8403 poltrona small girevole / small swivel armchair",
    id: "8403",
    code: "NDXOLVEAK2",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINO OPACO"],
    family: "Dep",
    projectCode: "LO4IZRX86R",
  },
  {
    name: "DOROTHEA SOFA",
    code: "VA78WX6Y02",
    hasFabric: true,
    hasWood: true,
    family: "Dorothea",
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "DOROTHEA SOFA PAR 16",
    code: "PDGKLZUFY0",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "DOR 01 composition",
    id: "DOR01",
    code: "9HKUZUKAW0",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "CYZAWHDIL9",
  },
  {
    name: "DOR 02 composition",
    id: "DOR02",
    code: "MR0TDKNEAT",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "GVELW5XPO0",
  },
  {
    name: "DOR 03 composition",
    id: "DOR03",
    code: "N5DFQZJS4Z",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "ASGAQJC1XD",
  },
  {
    name: "DOR 04 composition",
    id: "DOR04",
    code: "VQ34NVNIOW",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "VMOYRDNBDQ",
  },
  {
    name: "DOR 05 composition",
    id: "DOR05",
    code: "N2S7KFSKHF",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "VB4DYRWRYA",
  },
  {
    name: "DOR 06 composition",
    id: "DOR06",
    code: "M32MWSG9QJ",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "MRUI8IRKQV",
  },
  {
    name: "DOR 07 composition",
    id: "DOR07",
    code: "R0HRXA5WXT",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "TG74GZC862",
  },
  {
    name: "DOR 08 composition",
    id: "DOR08",
    code: "WQZSV48K9V",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "DWCCC638B8",
  },
  {
    name: "DOR 09 composition",
    id: "DOR09",
    code: "ZQDU0PL9KW",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "DOR 10 composition",
    id: "DOR10",
    code: "056WENIYTB",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "DOR 11 composition",
    id: "DOR11",
    code: "66JTD84BRI",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "DOR 12 composition",
    id: "DOR12",
    code: "VCFXPZ9N7X",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "DOR 13 composition",
    id: "DOR13",
    code: "6YT3KWQ2XV",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "DOR 14 composition",
    id: "DOR14",
    code: "62UC30082Y",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "DOR 15 composition",
    id: "DOR15",
    code: "URVHYCWI3U",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "DOR 16 composition",
    id: "DOR16",
    code: "NYVEBINREW",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "DOR 17 composition",
    id: "DOR17",
    code: "P858TNVYJM",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "DOR 18 composition",
    id: "DOR18",
    code: "4EGJ4UZJG3",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "DOR 19 composition",
    id: "DOR19",
    code: "W1MVF27VXI",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "DOR 20 composition",
    id: "DOR20",
    code: "3UBW6ZKIJQ",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "DOR 21 composition",
    id: "DOR21",
    code: "YXXK0J4ZMZ",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "8751N divano 2 posti / 2 seater sofa",
    id: "8751N",
    code: "LCHAB83ODH",
    hasFabric: true,
    hasWood: false,
    family: "Elle P",
    projectCode: "",
  },
  {
    name: "8752N divano 2 posti major / 2 seater sofa major",
    id: "8752N",
    code: "NCC7TTG6ZO",
    hasFabric: true,
    hasWood: false,
    family: "Elle P",
    projectCode: "",
  },
  {
    name: "8753N divano 3 posti / 3 seater sofa",
    id: "8753N",
    code: "8EXQ8WFQW3",
    hasFabric: true,
    hasWood: false,
    family: "Elle P",
    projectCode: "",
  },
  {
    name: "8750N poltrona / armchair",
    id: "8750N",
    code: "CKCM5P042B",
    hasFabric: true,
    hasWood: false,
    family: "Elle P",
    projectCode: "",
  },
  {
    name: "8790N poltrona small / small armchair",
    id: "8790N",
    code: "PLCO3HT0D1",
    hasFabric: true,
    hasWood: false,
    family: "Elle P",
    projectCode: "",
  },
  {
    name: "8756N divano 2 posti con 2 cuscini / 2 seater sofa with 2 cushions",
    id: "8756N",
    code: "2RUIRGE77M",
    hasFabric: true,
    hasWood: false,
    family: "Elle P",
    projectCode: "",
  },
  {
    name: "8757 divano 2 posti major con 2 cuscini / 2 seater sofa major with 2 cushions",
    id: "8757N",
    code: "DAEZK0VLZ9",
    hasFabric: true,
    hasWood: false,
    family: "Elle P",
    projectCode: "1N90QT9P6I",
  },
  {
    name: "8758N divano 3 posti con 3 cuscini / 3 seater sofa with 3 cushions",
    id: "8758N",
    code: "PFQRO0SLTJ",
    hasFabric: true,
    hasWood: false,
    family: "Elle P",
    projectCode: "",
  },
  {
    name: "8755N poltrona con 1 cuscino / armchair with 1 cushion",
    id: "8755N",
    code: "5VEUZEGK0M",
    hasFabric: true,
    hasWood: false,
    family: "Elle P",
    projectCode: "",
  },
  {
    name: "8791N poltrona small con 1 cuscino / small armchair with 1 cushion",
    id: "8791N",
    code: "HGI8C8P2B3",
    hasFabric: true,
    hasWood: false,
    family: "Elle P",
    projectCode: "",
  },
  {
    name: "2201N poltrona girevole / swivel armchair",
    id: "2201N",
    code: "RRRGYVZR97",
    family: "Folies Berger",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "",
  },
  {
    name: "2205NN divano 2 posti / 2 seater sofa",
    id: "2205NN",
    code: "H95QEWFIJE",
    family: "Folies",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ALLUMINIO LUCIDO",
      "ALLUMINO OPACO",
      "CROMATO LUCIDO",
      "METAL RAL 9005",
    ],
    projectCode: "",
  },
  {
    name: "2207NN divano 3 posti / 3 seater sofa",
    id: "2207NN",
    code: "N78Z9650LB",
    family: "Folies",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ALLUMINIO LUCIDO",
      "ALLUMINO OPACO",
      "CROMATO LUCIDO",
      "METAL RAL 9005",
    ],
    projectCode: "",
  },
  {
    name: "FOLIES MIDI POLTRONA CON BASE GIREVOLE",
    id: "2204N",
    code: "FSVV6B7YT3",
    family: "Folies",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ALLUMINIO LUCIDO",
      "ALLUMINO OPACO",
      "CROMATO LUCIDO",
      "METAL RAL 9005",
    ],
    projectCode: "NKNPN0M00I",
  },
  {
    name: "FOLIES MIDI POLTRONA CON GAMBE",
    id: "2203NN",
    code: "DZ8TOITYB7",
    family: "Folies",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ALLUMINIO LUCIDO",
      "ALLUMINO OPACO",
      "CROMATO LUCIDO",
      "METAL RAL 9005",
    ],
    projectCode: "157GLPJVGE",
  },
  {
    name: "2206N pouf con piedini / ottoman with small feet",
    id: "2206N",
    code: "YCDCM0Z6PU",
    family: "Folies",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ALLUMINIO LUCIDO",
      "ALLUMINO OPACO",
      "CROMATO LUCIDO",
      "METAL RAL 9005",
    ],
    projectCode: "3SYI739SZ9",
  },
  {
    name: "9000 poltrona / armchair",
    code: "FUYMAAGCR5",
    id: "9000",
    family: "Guest",
    hasFabric: true,
    hasWood: false,
    projectCode: "RZ2PLRI8QR",
  },
  {
    name: "9002 divano 2 posti major / 2 seater sofa major",
    code: "2W5LQHY3OS",
    id: "9002",
    family: "Guest",
    hasFabric: true,
    hasWood: false,
    projectCode: "5NVD53JREH",
  },
  {
    name: "9001 divano 2 posti / 2 seater sofa",
    code: "GLKJK0CISW",
    id: "9001",
    family: "Guest",
    hasFabric: true,
    hasWood: false,
    projectCode: "60V4M2DVSG",
  },
  {
    name: "9003 divano 3 posti / 3 seater sofa",
    code: "H1W8Y1KLFE",
    id: "9003",
    family: "Guest",
    hasFabric: true,
    hasWood: false,
    projectCode: "K7OJE0DSP1",
  },
  {
    name: "9004 penisola dx / right peninsula",
    code: "SWJ2X6MADA",
    id: "9004",
    family: "Guest",
    hasFabric: true,
    hasWood: false,
    projectCode: "0BVXEDWI6Z",
  },
  {
    name: "9005 penisola sx / left peninsula",
    code: "UECYH8XVQA",
    id: "9005",
    family: "Guest",
    hasFabric: true,
    hasWood: false,
    projectCode: "749PIS1N0R",
  },
  {
    name: "9025 penisola small dx / small right peninsula",
    code: "21R91WT18K",
    id: "9025",
    family: "Guest",
    hasFabric: true,
    hasWood: false,
    projectCode: "I0R2TVZKPR",
  },
  {
    name: "9026 penisola small sx / small left peninsula",
    code: "PNTMQKSQYP",
    id: "9026",
    family: "Guest",
    hasFabric: true,
    hasWood: false,
    projectCode: "RDRZBA864J",
  },
  {
    name: "9020 pouf d.100 / ottoman d.100",
    code: "HMOYHE4M55",
    id: "9020",
    family: "Guest",
    hasFabric: true,
    hasWood: false,
    projectCode: "6FY7ZP710I",
  },
  {
    name: "9021 pouf d.130 / ottoman d.130",
    code: "O9M7XUCJEO",
    id: "9021",
    family: "Guest",
    hasFabric: true,
    hasWood: false,
    projectCode: "CSH2TL7YBI",
  },
  {
    name: "9019 pouf d.70 / ottoman d.70",
    code: "X5EXVRVHF5",
    id: "9019",
    family: "Guest",
    hasFabric: true,
    hasWood: false,
    projectCode: "X54Y8ODDRL",
  },
  {
    name: "2017N poltrona / armchair",
    code: "4N8H7GPL13",
    id: "2017N",
    family: "Holiday",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "CROMATO LUCIDO",
      "METAL RAL 9005",
    ],
    projectCode: "T3P3NROUAD",
  },
  {
    name: "HOLIDAY POLTRONA",
    code: "JX6WLXIG4C",
    id: "2017/FSCN",
    family: "Holiday",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "CROMATO LUCIDO",
      "METAL RAL 9005",
    ],
    projectCode: "M07ZTITGD1",
  },
  {
    name: "2018N divano 2 posti / 2 seater sofa",
    code: "7B7WUTVP9I",
    id: "2018N",
    family: "Holiday",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "CROMATO LUCIDO",
      "METAL RAL 9005",
    ],
    projectCode: "",
  },
  {
    name: "2019N divano 3 posti / 3 seater sofa",
    code: "6F2XASWPX0",
    id: "2019N",
    family: "Holiday",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "CROMATO LUCIDO",
      "METAL RAL 9005",
    ],
    projectCode: "KETZZJWAYG",
  },
  {
    name: "2015N poltroncina / easy armchair",
    code: "7CWCYRG9IZ",
    id: "2015N",
    family: "Holiday",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "CROMATO LUCIDO",
      "METAL RAL 9005",
    ],
    projectCode: "9CGRRRAZYN",
  },
  {
    name: "2035 poltroncina con ruote / easy armchair with castors",
    code: "OX6KFJMOGM",
    id: "2035",
    family: "Holiday",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "CROMATO LUCIDO",
      "METAL RAL 9005",
    ],
    projectCode: "O3KIPV0S7K",
  },
  {
    name: "2020N poltrona alta / high armchair",
    code: "GJ95C8933A",
    id: "2020N",
    family: "Holiday",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "CROMATO LUCIDO",
      "METAL RAL 9005",
    ],
    projectCode: "INGGS2AZV2",
  },
  {
    name: "2034N sgabello / barstool",
    code: "OQ6VWNSYKB",
    id: "2034N",
    family: "Holiday",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "CROMATO LUCIDO",
      "METAL RAL 9005",
    ],
    projectCode: "QO8DEDPWIW",
  },
  {
    name: "1420 tavolino / coffee table",
    code: "NO9NA7ZQAP",
    id: "1420",
    family: "IDDA",
    hasFabric: false,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
      "METAL CIOCCOLATO",
      "METAL AZZURRO FIORDALISO",
      "METAL TE VERDE",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "V5GOPX6X6M",
  },
  {
    name: "1421 tavolino / coffee table",
    code: "ETDVX2UGB2",
    id: "1421",
    family: "IDDA",
    hasFabric: false,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
      "METAL CIOCCOLATO",
      "METAL AZZURRO FIORDALISO",
      "METAL TE VERDE",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "IBM86WXDEX",
  },
  {
    name: "2031NN divano 2 posti / 2 seater sofa",
    code: "DYECP6VCA8",
    id: "2031NN",
    family: "Jango",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "SRG3U18S87",
  },
  {
    name: "2030N poltrona girevole / swivel armchair",
    code: "BK255915CG",
    id: "2030N",
    family: "Jango",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "TIXFMMEVOO",
  },
  {
    name: "3032N poltrona girevole con base crociera / swivel armchair with cross base",
    code: "9CNF4NB19P",
    id: "3032N",
    family: "Jango",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "WYMC5BI2KA",
  },
  {
    name: "8841N divano 2 posti small / small 2 seater sofa",
    code: "6NAXBWE79N",
    id: "8841N",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "YTPQVP6AN5",
  },
  {
    name: "8848N pouf 180 / ottoman 180",
    code: "8MIVISS76Y",
    id: "8848N",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "38O7PV30NR",
  },
  {
    name: "8843N divano 2 posti / 2 seater sofa",
    id: "8843N",
    code: "FNDSLUI04O",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "S00IYLOOOE",
  },
  {
    name: "8844N divano 2 posti major / 2 seater sofa major",
    id: "8844N",
    code: "FRCCU3MDG4",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "H9UKPNLOGY",
  },
  {
    name: "8856N divano 3 posti alto / high 3 seater sofa",
    id: "8856N",
    code: "T94UY5I2KF",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "OXCK262NUJ",
  },
  {
    name: "8845N divano 3 posti / 3 seater sofa",
    id: "8845N",
    code: "JZP5IDBG04",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "LTEHA3MQUU",
  },
  {
    name: "8854N divano 2 posti alto / high 2 seater sofa",
    id: "8854N",
    code: "TE3PSV80R5",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "UVJIDSC34N",
  },
  {
    name: "8855N divano 2 posti major alto / high 2 seater sofa major",
    id: "8855N",
    code: "6LYFP7RLZ0",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "S74Q0A5IQO",
  },
  {
    name: "8852N divano 2 posti alto small / high small 2 seater sofa",
    id: "8852N",
    code: "IGOVKAKLN9",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "4B3GLRMJ6B",
  },
  {
    name: "8849N panca 140 / bench 140",
    id: "8849N",
    code: "LUFH6GGGLT",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "3Q49NMZJT9",
  },
  {
    name: "8850N panca 180 / bench 180",
    id: "8850N",
    code: "MJ9CQ78Y8W",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "LX1EGASI2Z",
  },
  {
    name: "8842N poltrona / armchair",
    id: "8842N",
    code: "YG5IYBDX9V",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "AG4QL3YIP3",
  },
  {
    name: "8853N poltrona alta / high armchair",
    id: "8853N",
    code: "ZVIQ3NB4A5",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "0I4DECOJ2G",
  },
  {
    name: "8851N poltrona alta small / high small armchair",
    id: "8851N",
    code: "11UZH33GEP",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "XNQKDY7V2X",
  },
  {
    name: " 8840N poltrona small / small armchair",
    id: "8840N",
    code: "J88QY9Z9KB",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "W72XFJOWVR",
  },
  {
    name: "8847N pouf 140 / ottoman 140",
    id: "8847N",
    code: "TWJNO8UTSJ",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "V8WT8OOA98",
  },
  {
    name: "8846N pouf 70 / ottoman 70",
    id: "8846N",
    code: "QVRDBVZGBI",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "R9TNEA9X33",
  },
  {
    name: "1110 tavolino / coffee table",
    code: "1NKFA13B04",
    id: "1110",
    hasFabric: false,
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "FRASSINO TINTA NOCE",
    ],
    customPiano: [
      "METAL AVORIO",
      "METAL AZZURRO",
      "METAL BRONZO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL OTTONE",
      "METAL PITCH BLACK",
      "METAL RAME",
      "METAL TE VERDE",
    ],
    hasPiano: true,
    family: "Keisho",
    projectCode: "D6YYUMHF78",
  },
  {
    name: "8691N panca 120 / bench 120",
    code: "DPXPIS8TVE",
    id: "8691N",
    family: "Line Bench",
    hasFabric: false,
    hasWood: true,
    hasPiano: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA NOCE",
      "METAL RAL 9016",
      "METAL RAL 3003",
      "METAL RAL 5014",
    ],
    customPiano: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA NOCE",
      "METAL RAL 9016",
      "METAL RAL 3003",
      "METAL RAL 5014",
    ],
    projectCode: "2S36MEVL56",
  },
  {
    name: "8692N panca 180 / bench 180",
    code: "E105R84ZN6",
    id: "8692N",
    family: "Line Bench",
    hasFabric: false,
    hasWood: true,
    hasPiano: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA NOCE",
      "METAL RAL 9016",
      "METAL RAL 3003",
      "METAL RAL 5014",
    ],
    customPiano: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA NOCE",
      "METAL RAL 9016",
      "METAL RAL 3003",
      "METAL RAL 5014",
    ],
    projectCode: "SV3POHHUCF",
  },
  {
    name: "5102N divano 2 posti / 2 seater sofa",
    code: "UF1P5WMGAG",
    id: "5102N",
    family: "Manhattan",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "FAGGIO NATURALE",
      "FAGGIO TINTA NOCE",
      "ALLUMINIO LUCIDO",
      "ALLUMINO OPACO",
    ],
    projectCode: "9QPI73CLMT",
  },
  {
    name: "5104N divano 3 posti / 3 seater sofa",
    code: "YDRU7DR3YN",
    id: "5104N",
    family: "Manhattan",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "FAGGIO NATURALE",
      "FAGGIO TINTA NOCE",
      "ALLUMINIO LUCIDO",
      "ALLUMINO OPACO",
    ],
    projectCode: "QB0F0132WS",
  },
  {
    name: "MANHATTAN DIVANO 2 POSTI MAJOR",
    code: "AZXUCBHLYZ",
    id: "5103N",
    family: "Manhattan",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "FAGGIO NATURALE",
      "FAGGIO TINTA NOCE",
      "ALLUMINIO LUCIDO",
      "ALLUMINO OPACO",
    ],
    projectCode: "B1IVOBVQX6",
  },
  {
    name: "5105N divano 3 posti major / 3 seater sofa major",
    code: "01TOJ2RPF4",
    id: "5105N",
    family: "Manhattan",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "FAGGIO NATURALE",
      "FAGGIO TINTA NOCE",
      "ALLUMINIO LUCIDO",
      "ALLUMINO OPACO",
    ],
    projectCode: "2NNS5RKSUF",
  },
  {
    name: "4102 divano 2 posti / 2 seater sofa",
    code: "Y9JD91ZH9Z",
    id: "4102N",
    family: "Matrix",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "GW9H3005LD",
  },
  {
    name: "4103N divano 2 posti major / 2 seater sofa major",
    code: "D9LKH8JAT8",
    id: "4103N",
    family: "Matrix",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "79F3EEITLE",
  },
  {
    name: "4104N divano 3 posti / 3 seater sofa",
    code: "NHBO95YQZF",
    id: "4104N",
    family: "Matrix",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "GPMTR6HEQX",
  },
  {
    name: "4101N poltrona / armchair",
    code: "CE6D69KPS9",
    id: "4101N",
    family: "Matrix",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "2G1H2XD2XG",
  },
  {
    name: "4202N poltroncina trendy con base slitta / trendy armchair with sled base",
    code: "RW4UOIXMAW",
    id: "4202N",
    family: "Matrix",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "B9GC2TIPGU",
  },
  {
    name: "4201N poltroncina trendy girevole",
    code: "A6GFRKA923",
    id: "4201N",
    family: "Matrix",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "PWHUCGDA1G",
  },
  {
    name: "9097_D100 tavolino / coffee table",
    code: "BJ0TFF5LY8",
    id: "9097",
    family: "Mil",
    hasFabric: false,
    hasWood: true,
    hasPiano: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "ROVERE SPAZZOLATO BIANCO",
    ],
    customPiano: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "VETRO TRASPARENTE",
      "VETRO FUME",
      "ROVERE SPAZZOLATO BIANCO",
      "MARMO MARQUINA",
      "MARMO CARRARA",
    ],
    projectCode: "ICQLFLEVC0",
  },
  {
    name: "9095_D50 tavolino / coffee table",
    code: "A1BN2N6UJ0",
    id: "9095",
    family: "Mil",
    hasFabric: false,
    hasWood: true,
    hasPiano: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "ROVERE SPAZZOLATO BIANCO",
    ],
    customPiano: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "VETRO TRASPARENTE",
      "VETRO FUME",
      "ROVERE SPAZZOLATO BIANCO",
      "MARMO MARQUINA",
      "MARMO CARRARA",
    ],
    projectCode: "PPQF66YFG4",
  },
  {
    name: "9096_D75 tavolino / coffee table",
    code: "EOCJBJQJX6",
    id: "9096",
    family: "Mil",
    hasFabric: false,
    hasWood: true,
    hasPiano: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "ROVERE SPAZZOLATO BIANCO",
    ],
    customPiano: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "VETRO TRASPARENTE",
      "VETRO FUME",
      "ROVERE SPAZZOLATO BIANCO",
      "MARMO MARQUINA",
      "MARMO CARRARA",
    ],
    projectCode: "70BZ10M1GM",
  },
  {
    name: "8560 pouf grande / big ottoman",
    code: "51RJR3VEHP",
    id: "8560",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL RAL 7021"],
    family: "Modo",
    projectCode: "XH7GMM7OIV",
  },
  {
    name: "8551 pouf piccolo / small ottoman",
    code: "DKTBCO4RK3",
    id: "8551",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL RAL 7021"],
    family: "Modo",
    projectCode: "USF6GZ3J1C",
  },
  {
    name: "5605 panca / bench",
    code: "O6R5KQFQ8L",
    id: "5605",
    family: "Molecule",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["METAL RAL 9005", "METAL RAL 9016", "CROMATO LUCIDO"],
    customPiano: ["WOOD RAL 9005", "WOOD RAL 9016"],
    projectCode: "T8B011H6CU",
  },
  {
    name: "5608 pouf rettangolare / rectangular ottoman",
    code: "VCKVFWQCVP",
    id: "5608",
    family: "Molecule",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["METAL RAL 9005", "METAL RAL 9016", "CROMATO LUCIDO"],
    customPiano: ["WOOD RAL 9005", "WOOD RAL 9016"],
    projectCode: "GDX0YKU1CT",
  },
  {
    name: "5606N tavolino / coffee table",
    code: "NIUHX6OM4B",
    id: "5606N",
    family: "Molecule",
    hasFabric: false,
    hasWood: true,
    hasPiano: true,
    customMaterial: ["METAL RAL 9005", "METAL RAL 9016", "CROMATO LUCIDO"],
    customPiano: ["WOOD RAL 9005", "WOOD RAL 9016"],
    projectCode: "XID6IB8WM2",
  },
  {
    name: "5604 pouf inserto / section ottoman",
    code: "O09VCQ92EP",
    id: "5604",
    family: "Molecule",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["METAL RAL 9005", "METAL RAL 9016", "CROMATO LUCIDO"],
    customPiano: ["WOOD RAL 9005", "WOOD RAL 9016"],
    projectCode: "AJ0F3TQ3S9",
  },

  {
    name: "1670 seduta 105x105 base laccata / seat 105x105 lacquered base",
    code: "LQ2BLBK8K6",
    id: "1670",
    family: "Acamante",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["METAL AVORIO", "METAL RAL 7021"],
    projectCode: "X01KRQD343",
  },
  {
    name: "1671 elemento sx 145x105 base laccata / left element lacquered base",
    code: "0C86WPYU6L",
    id: "1671",
    family: "Acamante",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["METAL AVORIO", "METAL RAL 7021"],
    projectCode: "E1I907M33U",
  },
  {
    name: "1672 elemento dx 145x105 base laccata / right element lacquered base",
    code: "039GCGWFVH",
    id: "1672",
    family: "Acamante",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["METAL AVORIO", "METAL RAL 7021"],
    projectCode: "BXVPYR33XN",
  },
  {
    name: "1673 dormeuse 210x105 base laccata / dormeuse lacquered base",
    code: "DUFSXX1FCU",
    id: "1673",
    family: "Acamante",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["METAL AVORIO", "METAL RAL 7021"],
    projectCode: "5MTTQMM4Q8",
  },
  {
    name: "1674 pouf 105x105 base laccata / ottoman lacquered base",
    code: "5OQWI77XPC",
    id: "1674",
    family: "Acamante",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["METAL AVORIO", "METAL RAL 7021"],
    projectCode: "N4FDHUZFRT",
  },

  {
    name: "1675 tavolo 105x105 base laccata / table 105x105 lacquered base",
    code: "ZSMI1594U4",
    id: "1675",
    family: "Acamante",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["METAL AVORIO", "METAL RAL 7021"],
    projectCode: "VR4UNHH673",
  },
  {
    name: "1750 elemento 92x92 / element 92x92",
    code: "G5G48VUV8J",
    id: "1750",
    family: "Create",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "CTAU1617NS",
  },
  {
    name: "1751 pouf / ottoman",
    code: "NLZROYAAHR",
    id: "1751",
    family: "Create",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "L05Z5FFYS4",
  },
  {
    name: "1752 elemento sx / element left",
    code: "510FAQCR98",
    id: "1752",
    family: "Create",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "ZXW9IQ8OX7",
  },
  {
    name: "1753 elemento dx / element right",
    code: "H5GQC9B5F4",
    id: "1753",
    family: "Create",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "S5I1XX0IQH",
  },
  {
    name: "1700 elemento 80 / element 80",
    code: "2RB7WPQ7TH",
    id: "1700",
    family: "Nhoto",
    hasFabric: true,
    hasWood: false,
    hasPiano: false,
    projectCode: "2XZFURX8QF",
  },
  {
    name: "1701 elemento 130 / element 130",
    code: "7360RBRVRG",
    id: "1701",
    family: "Nhoto",
    hasFabric: true,
    hasWood: false,
    hasPiano: false,
    projectCode: "81NVJ8D52M",
  },
  {
    name: "1702 elemento 130 sx / element left",
    code: "SIAG70YW32",
    id: "1702",
    family: "Nhoto",
    hasFabric: true,
    hasWood: false,
    hasPiano: false,
    projectCode: "MJHBYFIWHB",
  },
  {
    name: "1703 elemento 130 dx / element right",
    code: "QW3HVF2U9U",
    id: "1703",
    family: "Nhoto",
    hasFabric: true,
    hasWood: false,
    hasPiano: false,
    projectCode: "ES5FQI741D",
  },
  {
    name: "1704 poltrona / armchair",
    code: "FN3GMV08P2",
    id: "1704",
    family: "Nhoto",
    hasFabric: true,
    hasWood: false,
    hasPiano: false,
    projectCode: "5EVL8LHUBL",
  },
  {
    name: "1705 elemento 80 sx / element 80 left",
    code: "3QVOZ5MWL7",
    id: "1705",
    family: "Nhoto",
    hasFabric: true,
    hasWood: false,
    hasPiano: false,
    projectCode: "J2OV3FV19V",
  },
  {
    name: "1706 elemento 80 dx / element 80 right",
    code: "NCMIHE4Y9J",
    id: "1706",
    family: "Nhoto",
    hasFabric: true,
    hasWood: false,
    hasPiano: false,
    projectCode: "DA5FJ2NY5R",
  },
  {
    name: "1650 poltrona / armchair",
    code: "A52KEPD0A5",
    id: "1706",
    family: "FourFolds",
    hasFabric: true,
    hasWood: false,
    hasPiano: false,
    projectCode: "O0R80YIO94",
  },
  {
    name: "1651 pouf / ottoman",
    code: "CUEDKTY7MM",
    id: "1651",
    family: "FourFolds",
    hasFabric: true,
    hasWood: false,
    hasPiano: false,
    projectCode: "O0R80YIO94",
  },
  {
    name: "1620 divano sx / left sofa",
    code: "L3PGBHXJ51",
    id: "1620",
    hasFabric: true,
    hasWood: false,
    customCollection: [
      "Stretchy",
      "Trio",
      "Bit",
      "Uniform Mèlange",
      "Gentle 2",
    ],
    customMaterial: ["METAL RAL 9005"],
    family: "Lisse",
    projectCode: "0WXCZW6JOC",
  },
  {
    name: "1621 divano dx / right sofa",
    code: "NN88CS90EX",
    id: "1621",
    hasFabric: true,
    hasWood: false,
    customCollection: [
      "Stretchy",
      "Trio",
      "Bit",
      "Uniform Mèlange",
      "Gentle 2",
    ],
    customMaterial: ["METAL RAL 9005"],
    family: "Lisse",
    projectCode: "I5ZPMJ9RAA",
  },
  {
    name: "1622 pouf piccolo / small ottoman",
    code: "N80DJRBFCJ",
    id: "1622",
    hasFabric: true,
    hasWood: false,
    customCollection: [
      "Stretchy",
      "Trio",
      "Bit",
      "Uniform Mèlange",
      "Gentle 2",
    ],
    customMaterial: ["METAL RAL 9005"],
    family: "Lisse",
    projectCode: "V0MJ7ZFA05",
  },
  {
    name: "1623 pouf medio / medium ottoman",
    code: "FRFJVYNV02",
    id: "1623",
    hasFabric: true,
    hasWood: false,
    customCollection: [
      "Stretchy",
      "Trio",
      "Bit",
      "Uniform Mèlange",
      "Gentle 2",
    ],
    customMaterial: ["METAL RAL 9005"],
    family: "Lisse",
    projectCode: "WV3BAQO9DO",
  },
  {
    name: "1600 tavolino 40x40 / coffee table",
    code: "U9I5I1CRSJ",
    id: "1600",
    hasFabric: false,
    hasWood: false,
    hasPiano: true,
    customPiano: ["VETRO BRONZO", "VETRO FUME"],
    family: "Niby",
    projectCode: "NCCEZV7UG2",
  },
  {
    name: "1601 tavolino 70x70 / coffee table",
    code: "Z6VHLPAC0W",
    id: "1601",
    hasFabric: false,
    hasWood: false,
    hasPiano: true,
    customPiano: ["VETRO BRONZO", "VETRO FUME"],
    family: "Niby",
    projectCode: "HXSXJZ2G6T",
  },

  {
    name: "5607 pouf quadro / square ottoman",
    code: "SXUN8CYNHT",
    id: "5607",
    family: "Molecule",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["METAL RAL 9005", "METAL RAL 9016", "CROMATO LUCIDO"],
    customPiano: ["WOOD RAL 9005", "WOOD RAL 9016"],
    projectCode: "HZMJ07UPB7",
  },
  {
    name: "5603 pouf tondo / round ottoman",
    code: "09YAMJY0IZ",
    id: "5603",
    family: "Molecule",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["METAL RAL 9005", "METAL RAL 9016", "CROMATO LUCIDO"],
    customPiano: ["WOOD RAL 9005", "WOOD RAL 9016"],
    projectCode: "ANZ5PV9FIS",
  },
  {
    name: "3002N divano 2 posti / 2 seater sofa ",
    code: "741CNX3PLN",
    id: "3002N",
    family: "Mon Coeur",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["METAL RAL 9005", "METAL RAL 9016", "CROMATO LUCIDO"],
    projectCode: "ELZG0G2PNI",
  },
  {
    name: "3001N poltrona girevole",
    code: "CGYMRP618W",
    id: "3001N",
    family: "Mon Coeur",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["METAL RAL 9005", "METAL RAL 9016", "CROMATO LUCIDO"],
    projectCode: "Z5EHUF4Y3B",
  },
  {
    name: "3100N poltrona girevole / swivel armchair",
    code: "UGKWJ3TXO9",
    id: "3100N",
    family: "Mon Petit",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "9KTH1NPU63",
  },
  {
    name: "8106N divano 2 posti / 2 seater sofa",
    code: "56AGTJ396Z",
    id: "8106N",
    family: "Mrs. Buffy",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "5IMD6UKXJZ",
  },
  {
    name: "8105N poltrona / armchair",
    code: "WESIF4JIS5",
    id: "8105N",
    family: "Mrs. Buffy",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "P5MJO9MRAQ",
  },
  {
    name: "8108N divano 3 posti / 3 seater sofa",
    code: "HXKFI9KXEW",
    id: "8108N",
    family: "Mrs. Buffy",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "G93KCYWXYA",
  },
  {
    name: "8101N poltrona small / small armchair",
    code: "5CL0DBG3XR",
    id: "8101N",
    family: "Mrs. Buffy",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "S148WTSUHA",
  },
  {
    name: "8107N divano 2 posti major / 2 seater sofa major",
    code: "HRB6KJCWLA",
    id: "8107N",
    family: "Mrs. Buffy",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "OICJG0HIO5",
  },
  {
    name: "9050N_wood sedia lounge girevole / swivel lounge chair",
    family: "Myplace",
    id: "9050N",
    code: "7EW7MIKME5",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ROVERE SPAZZOLATO NATURALE", "ROVERE SPAZZOLATO GRAFITE"],
    projectCode: "O0JS7T7756",
  },
  {
    name: "9054N_wood sedia lounge girevole / swivel lounge chair",
    family: "Myplace",
    id: "9054N",
    code: "QCYB5Y94XA",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ROVERE SPAZZOLATO NATURALE", "ROVERE SPAZZOLATO GRAFITE"],
    projectCode: "BI0O202AFC",
  },
  {
    name: "9061N_metal sedia lounge girevole / swivel lounge chair",
    family: "Myplace",
    id: "9061N",
    code: "4RXJLY82OA",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL PITCH BLACK", "METAL AVORIO"],
    projectCode: "7M2D758PV8",
  },
  {
    name: "9062N_sled sedia lounge / lounge chair",
    id: "9062N",
    code: "CP0LGETDB6",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL PITCH BLACK",
      "METAL AVORIO",
    ],
    projectCode: "K8V0GZRXLZ",
  },
  {
    name: "9063N_sled sedia lounge / lounge chair",
    id: "9063N",
    code: "DPNKXYVHOU",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL PITCH BLACK",
      "METAL AVORIO",
    ],
    projectCode: "9N838CM4NW",
  },
  {
    name: "9064N_sled sedia lounge / lounge chair",
    id: "9064N",
    code: "586PSWIH82",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL PITCH BLACK",
      "METAL AVORIO",
    ],
    projectCode: "5CTAHQ16OK",
  },
  {
    name: "9065N_sled sedia lounge / lounge chair",
    id: "9065N",
    code: "JOS2OLYBVB",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL PITCH BLACK",
      "METAL AVORIO",
    ],
    projectCode: "8IAGSCPJL5",
  },
  {
    name: "9066N_sled sedia lounge / lounge chair",
    id: "9066N",
    code: "X9A4XTGPCU",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL PITCH BLACK",
      "METAL AVORIO",
    ],
    projectCode: "W7K0R29LBE",
  },
  {
    name: "9067N_sled sedia lounge / lounge chair",
    id: "9067N",
    code: "01WPFDMFOC",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL PITCH BLACK",
      "METAL AVORIO",
    ],
    projectCode: "RX66RL4V82",
  },
  {
    name: "9051N_wood sedia lounge girevole / swivel lounge chair",
    id: "9051N",
    code: "5AY4ASHMF3",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ROVERE SPAZZOLATO NATURALE", "ROVERE SPAZZOLATO GRAFITE"],
    projectCode: "YZ9ZLM0P5I",
  },
  {
    name: "9052N_wood sedia lounge girevole / swivel lounge chair",
    id: "9052N",
    code: "ZA847NMQG9",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ROVERE SPAZZOLATO NATURALE", "ROVERE SPAZZOLATO GRAFITE"],
    projectCode: "XCUCNB565P",
  },
  {
    name: "9053N_wood sedia lounge girevole / swivel lounge chair",
    id: "9053N",
    code: "30RVXOG8XI",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ROVERE SPAZZOLATO NATURALE", "ROVERE SPAZZOLATO GRAFITE"],
    projectCode: "4TK5QCHUK0",
  },
  {
    name: "9055N_wood sedia lounge girevole / swivel lounge chair",
    id: "9055N",
    code: "D9NHU4FN66",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ROVERE SPAZZOLATO NATURALE", "ROVERE SPAZZOLATO GRAFITE"],
    projectCode: "APXTH185PG",
  },
  {
    name: "9056N_metal sedia lounge girevole / swivel lounge chair ",
    id: "9056N",
    code: "JNVT0B46RE",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL PITCH BLACK", "METAL AVORIO"],
    projectCode: "ZWHMEANCC4",
  },
  {
    name: "9057N_metal sedia lounge girevole / swivel lounge chair",
    id: "9057N",
    code: "J843BSIAGT",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL PITCH BLACK", "METAL AVORIO"],
    projectCode: "0Z1G62D4GV",
  },
  {
    name: "9058N_metal sedia lounge girevole / swivel lounge chair",
    id: "9058N",
    code: "C05EKJ3G5D",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL PITCH BLACK", "METAL AVORIO"],
    projectCode: "3KDBVZ1M9U",
  },
  {
    name: "9059N_metal sedia lounge girevole / swivel lounge chair",
    id: "9059N",
    code: "TE7HES9V57",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL PITCH BLACK", "METAL AVORIO"],
    projectCode: "GL3RZE6SO8",
  },
  {
    name: "9060N_metal sedia lounge girevole / swivel lounge chair",
    id: "9060N",
    code: "TKEBA5E8BU",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL PITCH BLACK", "METAL AVORIO"],
    projectCode: "B7K9W8HWVK",
  },
  {
    name: "1071N  divano 2 posti / 2 seater sofa",
    family: "Nap",
    id: "1071N",
    code: "CTJUEDJYP8",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "I8OGC75CCD",
  },
  {
    name: "1092N divano 3 posti con cuscini / 3 seater sofa with cushions",
    family: "Nap",
    id: "1092N",
    code: "QZYG2B09L3",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "JRRHCPLCTW",
  },
  {
    name: "1074N divano 4 posti / 4 seater sofa",
    family: "Nap",
    id: "1074N",
    code: "SSF9ISHRUH",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "CWL07K5581",
  },
  {
    name: "1091N divano 2 posti con cuscini / 2 seater sofa with cushions",
    family: "Nap",
    id: "1091N",
    code: "KNL91M3030",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "NXAG7J9SG9",
  },
  {
    name: "1072N divano 3 posti / 3 seater sofa",
    family: "Nap",
    id: "1072N",
    code: "UJ6MJBBOSK",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "J26BO95SX4",
  },
  {
    name: "1073N divano 3 posti major / 3 seater sofa major",
    family: "Nap",
    id: "1073N",
    code: "0MVM0GXTSX",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "9HG9NOJ446",
  },
  {
    name: "1093N divano 3 posti major con cuscini / 3 seater sofa major with cushions",
    family: "Nap",
    id: "1093N",
    code: "EFYL06OB4A",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "UZP7HDNA7B",
  },
  {
    name: "1094N divano 4 posti con cuscini / 4 seater sofa with cushions",
    family: "Nap",
    id: "1094N",
    code: "KZ21PQCO51",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "5YX7ROCNAO",
  },
  {
    name: "1070N poltrona / armchair",
    family: "Nap",
    id: "1070N",
    code: "AJYO4UKCR8",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "H5YD8RPN24",
  },
  {
    name: "8702 poltrona / armchair",
    code: "NP7OR4WQQY",
    id: "8702",
    hasFabric: true,
    hasWood: false,
    family: "Nautile",
    projectCode: "KDMP47JIV5",
  },
  {
    name: "1445 angolo / corner",
    code: "T6D5ACRJRO",
    id: "1445",
    hasFabric: true,
    hasWood: true,
    family: "Newtime",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "8P006OOHWQ",
  },
  {
    name: "1441 divano 2 posti / 2 seaters sofa",
    code: "817E5R6H8K",
    id: "1441",
    hasFabric: true,
    hasWood: true,
    family: "Newtime",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "3TRJIF0APY",
  },
  {
    name: "1442 divano 3 posti / 3 seaters sofa",
    code: "SF4C7LBGV1",
    id: "1442",
    hasFabric: true,
    hasWood: true,
    family: "Newtime",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "LSG0PNY9FM",
  },
  {
    name: "1440 poltrona / armchair",
    code: "NWXEJXR7MO",
    id: "1440",
    hasFabric: true,
    hasWood: true,
    family: "Newtime",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "EP53HD3UEN",
  },
  {
    name: "1449 pouf 140x90 / pouf 140x90",
    code: "7LFY4YEZYG",
    id: "1449",
    hasFabric: true,
    hasWood: true,
    family: "Newtime",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "DBW8CO0RBP",
  },
  {
    name: "1450 pouf 210x90 / pouf 210x90",
    code: "25PC320TNL",
    id: "1450",
    hasFabric: true,
    hasWood: true,
    family: "Newtime",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "A16LWKZ58M",
  },
  {
    name: "1448 pouf 70x90 / pouf 70x90",
    code: "QGYB2MGU6U",
    id: "1448",
    hasFabric: true,
    hasWood: true,
    family: "Newtime",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "NWC709TRIV",
  },
  {
    name: "NEWTIME SOFA",
    code: "F64LZMALPH",
    hasFabric: true,
    hasWood: true,
    family: "Newtime",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "",
  },
  {
    name: "NODE PLUS SOFA",
    code: "SEFV479SM5",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    family: "Node Plus",
    projectCode: "",
  },
  {
    name: "OSAKA SOFA",
    code: "ENUQUNXR4G",
    hasFabric: true,
    hasWood: true,
    customCollection: [
      "Stretchy",
      "Bit",
      "Mosaic 2",
      "Uniform Mèlange",
      "Triangle",
      "Gentle 2",
    ],
    customMaterial: ["METAL RAL 9005"],
    family: "Osaka",
    projectCode: "",
  },
  {
    name: "8801 divano 180 / sofa 180",
    code: "F5LZBQUIQA",
    id: "8801",
    hasFabric: true,
    hasWood: true,
    customCollection: [
      "Stretchy",
      "Bit",
      "Mosaic 2",
      "Uniform Mèlange",
      "Triangle",
      "Gentle 2",
    ],
    customMaterial: ["METAL RAL 9005"],
    family: "Osaka",
    projectCode: "A7JOB02BFJ",
  },
  {
    name: "8802 divano 230 / sofa 230",
    code: "THWSW9VF11",
    id: "8802",
    hasFabric: true,
    hasWood: true,
    customCollection: [
      "Stretchy",
      "Bit",
      "Mosaic 2",
      "Uniform Mèlange",
      "Triangle",
      "Gentle 2",
    ],
    customMaterial: ["METAL RAL 9005"],
    family: "Osaka",
    projectCode: "NXJVNH0V1W",
  },
  {
    name: "8803 divano 280 / sofa 280",
    code: "JP2MN103EV",
    id: "8803",
    hasFabric: true,
    hasWood: true,
    customCollection: [
      "Stretchy",
      "Bit",
      "Mosaic 2",
      "Uniform Mèlange",
      "Triangle",
      "Gentle 2",
    ],
    customMaterial: ["METAL RAL 9005"],
    family: "Osaka",
    projectCode: "EQKGH2UK6C",
  },
  {
    name: "8804 divano 330 / sofa 330",
    code: "J7FKPL1D30",
    id: "8804",
    hasFabric: true,
    hasWood: true,
    customCollection: [
      "Stretchy",
      "Bit",
      "Mosaic 2",
      "Uniform Mèlange",
      "Triangle",
      "Gentle 2",
    ],
    customMaterial: ["METAL RAL 9005"],
    family: "Osaka",
    projectCode: "K4T36O31IG",
  },
  {
    name: "8805 divano 380 / sofa 380",
    code: "8QW173RUHF",
    id: "8805",
    hasFabric: true,
    hasWood: true,
    customCollection: [
      "Stretchy",
      "Bit",
      "Mosaic 2",
      "Uniform Mèlange",
      "Triangle",
      "Gentle 2",
    ],
    customMaterial: ["METAL RAL 9005"],
    family: "Osaka",
    projectCode: "M47XGSMDE6",
  },
  {
    name: "8806 divano 430 / sofa 430",
    code: "QET852VVBK",
    id: "8806",
    hasFabric: true,
    hasWood: true,
    customCollection: [
      "Stretchy",
      "Bit",
      "Mosaic 2",
      "Uniform Mèlange",
      "Triangle",
      "Gentle 2",
    ],
    customMaterial: ["METAL RAL 9005"],
    family: "Osaka",
    projectCode: "JLLJUZ7U7Y",
  },
  {
    name: "8807 divano 480 / sofa 480",
    code: "C79XYBFCC3",
    id: "8807",
    hasFabric: true,
    hasWood: true,
    customCollection: [
      "Stretchy",
      "Bit",
      "Mosaic 2",
      "Uniform Mèlange",
      "Triangle",
      "Gentle 2",
    ],
    customMaterial: ["METAL RAL 9005"],
    family: "Osaka",
    projectCode: "CN6OSLAOWV",
  },
  {
    name: "8808 divano 530 / sofa 530",
    code: "MQVTJSH3TZ",
    id: "8808",
    hasFabric: true,
    hasWood: true,
    customCollection: [
      "Stretchy",
      "Bit",
      "Mosaic 2",
      "Uniform Mèlange",
      "Triangle",
      "Gentle 2",
    ],
    customMaterial: ["METAL RAL 9005"],
    family: "Osaka",
    projectCode: "GK1OLXN7SY",
  },
  {
    name: "8809 divano 580 / sofa 580",
    code: "ZUZJO2P4FY",
    id: "8809",
    hasFabric: true,
    hasWood: true,
    customCollection: [
      "Stretchy",
      "Bit",
      "Mosaic 2",
      "Uniform Mèlange",
      "Triangle",
      "Gentle 2",
    ],
    customMaterial: ["METAL RAL 9005"],
    family: "Osaka",
    projectCode: "J7A4GJ8ZYN",
  },
  {
    name: "8810 divano 630 / sofa 630",
    code: "J8ASARI4RR",
    id: "8810",
    hasFabric: true,
    hasWood: true,
    customCollection: [
      "Stretchy",
      "Bit",
      "Mosaic 2",
      "Uniform Mèlange",
      "Triangle",
      "Gentle 2",
    ],
    customMaterial: ["METAL RAL 9005"],
    family: "Osaka",
    projectCode: "RXVXQ8997B",
  },
  {
    name: "SCANCARO TAVOLINO DIAMETRO 110 H.25 INCLINATO",
    id: "8836",
    code: "JX6WOQJY99",
    family: "Scancaro",
    hasFabric: false,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
      "METAL CIOCCOLATO",
      "METAL AZZURRO FIORDALISO",
      "METAL TE VERDE",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "0YRG1E1SJG",
  },
  {
    name: "8830 tavolino / coffee table",
    id: "8830",
    code: "8NX9JMDJGF",
    family: "Scancaro",
    hasFabric: false,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
      "METAL CIOCCOLATO",
      "METAL AZZURRO FIORDALISO",
      "METAL TE VERDE",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "1OR32QSTAC",
  },
  {
    name: "8832 tavolino / coffee table",
    id: "8832",
    code: "DPY4P3ZBFF",
    family: "Scancaro",
    hasFabric: false,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
      "METAL CIOCCOLATO",
      "METAL AZZURRO FIORDALISO",
      "METAL TE VERDE",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "9TCSS72VWD",
  },
  {
    name: "8831 tavolino / coffee table",
    id: "8831",
    code: "7UHYX6NGRC",
    family: "Scancaro",
    hasFabric: false,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
      "METAL CIOCCOLATO",
      "METAL AZZURRO FIORDALISO",
      "METAL TE VERDE",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "N2TR9AV7UZ",
  },
  {
    name: "8833 tavolino / coffee table",
    id: "8833",
    code: "URDE9DHAI9",
    family: "Scancaro",
    hasFabric: false,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
      "METAL CIOCCOLATO",
      "METAL AZZURRO FIORDALISO",
      "METAL TE VERDE",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "PSBS515G4P",
  },
  {
    name: "8834 tavolino / coffee table",
    id: "8834",
    code: "I9OK6AM3WJ",
    family: "Scancaro",
    hasFabric: false,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
      "METAL CIOCCOLATO",
      "METAL AZZURRO FIORDALISO",
      "METAL TE VERDE",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "E6G9K44R0Z",
  },
  {
    name: "8835 tavolino / coffee table",
    id: "8835",
    code: "ZV7VXTR7HY",
    family: "Scancaro",
    hasFabric: false,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
      "METAL CIOCCOLATO",
      "METAL AZZURRO FIORDALISO",
      "METAL TE VERDE",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "2LU5IKJ1GT",
  },
  {
    name: "1160 sedia lounge / lounge chair",
    code: "9TJXJS3710",
    id: "1160",
    hasFabric: true,
    family: "Phar Lap",
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "4JKV5HGMN1",
  },
  {
    name: "PINCH SOFA",
    code: "D2KVHLEDBI",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "",
  },
  {
    name: "9089_h.140 divano 2 posti / 2 seater sofa",
    code: "BWTK4PDRR2",
    id: "9089",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "30ZUEEPFYS",
  },
  {
    name: "9081_h.70 divano 2 posti / 2 seater sofa",
    code: "EDQC1AVFQP",
    id: "9081",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "RL59AB0PMW",
  },
  {
    name: "9085_h.90 divano 2 posti / 2 seater sofa",
    code: "IB4K5SDI6F",
    id: "9085",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "PG7C3YCTEF",
  },
  {
    name: "9090_h.140 divano 3 posti / 3 seater sofa",
    code: "5ZAIS15G4S",
    id: "9090",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "FSOTSX7POR",
  },
  {
    name: "9082_h.70 divano 3 posti / 3 seater sofa",
    code: "C08HW4XP3E",
    id: "9082",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "4Q4WCCQO93",
  },
  {
    name: "9086_h.90 divano 3 posti / 3 seater sofa",
    code: "R0M6EYA8UV",
    id: "9086",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "QHBMYZFJLM",
  },
  {
    name: "9091_h.140 divano 3 posti major / 3 seater sofa major",
    code: "B45NSYW4M0",
    id: "9091",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "K8KND8NGIU",
  },
  {
    name: "9083_h.70 divano 3 posti major / 3 seater sofa major",
    code: "RVL14C0LHM",
    id: "9083",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "QRJXBDRJUA",
  },
  {
    name: "9087_h.90 divano 3 posti major / 3 seater sofa major ",
    code: "SKRKKI19AW",
    id: "9087",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "N3HEV5F614",
  },
  {
    name: "9101_h.140_slim divano 2 posti / 2 seater sofa ",
    code: "QH9HVJFU11",
    id: "9101",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "OM5TGFO0WR",
  },
  {
    name: "9102_h.140_slim divano 3 posti / 3 seater sofa",
    code: "L3FET1I8LO",
    id: "9102",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "NDQM7MKAI9",
  },
  {
    name: "9103_h.140_slim divano 3 posti major / 3 seater sofa major",
    code: "6ZDO8VPQXU",
    id: "9103",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "B6LF02XQFZ",
  },
  {
    name: "9088_h.140 poltrona / armchair",
    code: "WPANLVHQ4W",
    id: "9088",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "UYK0797PTY",
  },
  {
    name: "9080_h.70 poltrona / armchair",
    code: "ES76O3ATNA",
    id: "9080",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "WUWDPAXFJA",
  },
  {
    name: "9084_h.90 poltrona / armchair",
    code: "XO8N5FBJGK",
    id: "9084",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "D52ETRUBGY",
  },
  {
    name: "9100_h.140_slim poltrona / armchair  ",
    code: "FRDUCBPUIP",
    id: "9100",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "CZBC9VEASG",
  },
  {
    name: "8762_D110 pouf / ottoman",
    code: "TJCYBHVGHV",
    id: "8762_D110_POUFF",
    hasFabric: false,
    hasWood: false,
    family: "Ploff",
    projectCode: "EI9V3R4DCZ",
  },
  {
    name: "8760_D70 pouf / ottoman",
    code: "C5D3CYFT39",
    id: "8760_D70_POUFF",
    hasFabric: false,
    hasWood: false,
    family: "Ploff",
    projectCode: "9SDTAHIS1C",
  },
  {
    name: "8761_D90 pouf / ottoman",
    code: "5CXGEVTQMN",
    id: "8761_D90_POUFF",
    hasFabric: false,
    hasWood: false,
    family: "Ploff",
    projectCode: "DQ0V872VPK",
  },
  {
    name: "PUZZLE DIVANO",
    code: "ML610RF36S",
    hasFabric: true,
    hasWood: false,
    family: "Puzzle",
    projectCode: "",
  },
  {
    name: "5205 divano 2 posti big / big 2 seater sofa",
    code: "CD02A5XMA0",
    id: "5205",
    family: "Puzzle",
    hasFabric: true,
    hasWood: false,
    projectCode: "EY4WVFNT6A",
  },
  {
    name: "5207 divano 2 posti major big / big 2 seater sofa major",
    code: "C2OVVIPNPD",
    id: "5207",
    family: "Puzzle",
    hasFabric: true,
    hasWood: false,
    projectCode: "T8UDNC7QE1",
  },
  {
    name: "5206 divano 2 posti major small / small 2 seater sofa major",
    code: "S1VOMUPQQP",
    id: "5206",
    family: "Puzzle",
    hasFabric: true,
    hasWood: false,
    projectCode: "4K3EAHKWPW",
  },
  {
    name: "5204 divano 2 posti small / small 2 seater sofa",
    code: "OO73WPSM2I",
    id: "5204",
    family: "Puzzle",
    hasFabric: true,
    hasWood: false,
    projectCode: "5JUEUEO3VK",
  },
  {
    name: "5209 divano 3 posti big / big 3 seater sofa",
    code: "UGND4WYJ4S",
    id: "5209",
    family: "Puzzle",
    hasFabric: true,
    hasWood: false,
    projectCode: "Z749F79B62",
  },
  {
    name: "5208 divano 3 posti small / small 3 seater sofa",
    code: "WNRSGDWIII",
    id: "5208",
    family: "Puzzle",
    hasFabric: true,
    hasWood: false,
    projectCode: "7QP17QY6QD",
  },
  {
    name: "5223 dormeuse big dx / big right dormeuse",
    code: "G0SXCR8J06",
    id: "5223",
    family: "Puzzle",
    hasFabric: true,
    hasWood: false,
    projectCode: "1OZPIOGP0V",
  },
  {
    name: "5225 dormeuse big sx / big left dormeuse",
    code: "R06904RURI",
    id: "5225",
    family: "Puzzle",
    hasFabric: true,
    hasWood: false,
    projectCode: "F2RQ5NE90G",
  },
  {
    name: "5222dormeuse small dx / small right dormeuse",
    code: "8QP8STORUL",
    id: "5222",
    family: "Puzzle",
    hasFabric: true,
    hasWood: false,
    projectCode: "ZUHXJGJFZ2",
  },
  {
    name: "5224 dormeuse small sx / small left dormeuse",
    code: "C9U0VCXEOZ",
    id: "5224",
    family: "Puzzle",
    hasFabric: true,
    hasWood: false,
    projectCode: "KV2BWURLWG",
  },
  {
    name: "5202 poltrona small / small armchair",
    code: "0JWCI6DOMG",
    id: "5202",
    family: "Puzzle",
    hasFabric: true,
    hasWood: false,
    projectCode: "SEI9HOU571",
  },
  {
    name: "5203 poltrona big / big armchair",
    code: "FZGIOIE0AG",
    id: "5203",
    family: "Puzzle",
    hasFabric: true,
    hasWood: false,
    projectCode: "K9CWZDI450",
  },
  {
    name: "SERIE 50 POLTRONA",
    code: "XT4G1FBA1L",
    hasFabric: true,
    family: "Serie 50",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA WENGE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 7021",
    ],
    projectCode: "",
  },
  {
    name: "8710/AN poltrona alta / high armchair",
    code: "039Q08CJ3R",
    id: "8710/AN",
    family: "Serie 50",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA WENGE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 7021",
    ],
    projectCode: "2E9D2RIS4V",
  },
  {
    name: "8711/AN poltrona alta large / high large armchair",
    code: "JMPVNL74LE",
    id: "8711/AN",
    family: "Serie 50",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA WENGE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 7021",
    ],
    projectCode: "JK1VUMN0AD",
  },
  {
    name: "8713/B poltrona bassa / low armchair",
    code: "YZO7S5UJ9A",
    id: "8713/BN",
    family: "Serie 50",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA WENGE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 7021",
    ],
    projectCode: "O58M4STF8X",
  },
  {
    name: "8716/C divano 2 posti / 2 seater sofa",
    code: "KI6OEU9WU1",
    id: "8716/CN",
    family: "Serie 50",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA WENGE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 7021",
    ],
    projectCode: "OQOY4J6J0I",
  },
  {
    name: "8717/C divano 2 posti major / 2 seater sofa major",
    code: "Y0HSR5W619",
    id: "8717/CN",
    family: "Serie 50",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA WENGE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 7021",
    ],
    projectCode: "GQ5648IC35",
  },
  {
    name: "8718/C divano 3 posti / 3 seater sofa",
    code: "TL8KUEC35C",
    id: "8718/CN",
    family: "Serie 50",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA WENGE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 7021",
    ],
    projectCode: "RWEXWE0VFS",
  },
  {
    name: "8715/C poltrona / armchair",
    code: "KKS4AH2UCT",
    id: "8715/CN",
    family: "Serie 50",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA WENGE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 7021",
    ],
    projectCode: "GMP8QSCMV8",
  },
  {
    name: "8722/D sedia lounge / lounge chair",
    code: "FXIOJ9LG60",
    id: "8722/DN",
    family: "Serie 50",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA WENGE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 7021",
    ],
    projectCode: "LFSW7FTPBW",
  },
  {
    name: "SHES SGABELLO",
    code: "MGSCUT1TEA",
    id: "8775N",
    hasFabric: true,
    family: "Shes",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "9QXBZQX8HI",
  },
  {
    name: "1035N divano 3 posti con braccioli / 3 seater with arms",
    code: "3S3GU3RO7J",
    id: "1035N",
    hasFabric: true,
    family: "Soave",
    hasWood: true,
    customMaterial: [
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "UXNCFJAAEU",
  },
  {
    name: "1034N divano 3 posti / 3 seater sofa",
    code: "9W95VDW25D",
    id: "1034N",
    hasFabric: true,
    family: "Soave",
    hasWood: true,
    customMaterial: [
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "NXHN3Q564T",
  },
  {
    name: "1033N divano 2 posti con braccioli / 2 seater with arms",
    code: "Y5X2Q67LQF",
    id: "1033N",
    hasFabric: true,
    family: "Soave",
    hasWood: true,
    customMaterial: [
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "K26GX0M36B",
  },
  {
    name: "1032N divano 2 posti / 2 seater sofa",
    code: "ACUSDN6TJG",
    id: "1032N",
    hasFabric: true,
    family: "Soave",
    hasWood: true,
    customMaterial: [
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "GFQC4YR90C",
  },
  {
    name: "1030N poltrona / armchair",
    code: "HMB8QQJFU0",
    id: "1030N",
    hasFabric: true,
    family: "Soave",
    hasWood: true,
    customMaterial: [
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "KHXELCHYHP",
  },
  {
    name: "1031N poltrona con braccioli / armchair with arms",
    code: "QJAR72EHWX",
    id: "1031N",
    hasFabric: true,
    family: "Soave",
    hasWood: true,
    customMaterial: [
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "9B62LA0S5X",
  },
  {
    name: "1223NN divano 2 posti / 2 seater sofa",
    code: "VPCU0JD4SJ",
    id: "1223NN",
    hasFabric: true,
    family: "Socrate",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "B6PK8XRWWP",
  },
  {
    name: "1222NN poltrona / armchair",
    code: "WGXKA7OK2V",
    id: "1222NN",
    hasFabric: true,
    family: "Socrate",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "FFN969D8V6",
  },
  {
    name: "1253NN panca / bench",
    code: "I2FLJY0GBW",
    id: "1253NN",
    hasFabric: true,
    family: "Socrate",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "SXTK4HLN2X",
  },
  {
    name: "1252NN pouf / ottoman",
    code: "SGAFIDN34F",
    id: "1252NN",
    hasFabric: true,
    family: "Socrate",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "QQPOB6BE95",
  },
  {
    name: "2234N poltroncina con piedi / small armchair with feet",
    code: "1KEHVF4TV9",
    id: "2234N",
    hasFabric: true,
    family: "Speak Easy",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "ALLUMINIO LUCIDO"],
    projectCode: "X6S4AZVIZB",
  },
  {
    name: "2233N poltroncina girevole / swivel small armchair",
    code: "9ELDIHGYC7",
    id: "2233N",
    hasFabric: true,
    family: "Speak Easy",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "ALLUMINIO LUCIDO"],
    projectCode: "UXN6KLY6JM",
  },
  {
    name: "5703 divano 2 posti / 2 seater sofa",
    code: "8S2CRQEML9",
    hasFabric: true,
    family: "Stones",
    id: "5703",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "Q6TPC7G9MB",
  },
  {
    name: "5704 divano 3 posti / 3 seater sofa",
    code: "QPEHAKG41O",
    hasFabric: true,
    family: "Stones",
    id: "5704",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "US44GSPE5M",
  },
  {
    name: "5705 dormeuse / dormeuse",
    code: "8TE26OCDAU",
    hasFabric: true,
    family: "Stones",
    id: "5705",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "Z0TC4XCND2",
  },
  {
    name: "5702 poltrona / armchair",
    code: "THQL8PWPH1",
    hasFabric: true,
    family: "Stones",
    id: "5702",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "LCJM3OXJAG",
  },
  {
    name: "5701 sedia / chair",
    code: "M0XY7F7QX5",
    hasFabric: true,
    family: "Stones",
    id: "5701",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "Z1VA0NLHAL",
  },
  {
    name: "SUI01 composition",
    code: "PQIS1COTKI",
    id: "SUI01",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "VMID6VFYC5",
  },
  {
    name: "SUI02 composition",
    code: "QOGXIFX3NB",
    id: "SUI02",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "FCXLHEV3FH",
  },
  {
    name: "SUI03 composition",
    code: "EWWXF3ZJRF",
    id: "SUI03",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "ODD86PEU5G",
  },
  {
    name: "SUI04 composition",
    code: "Q98RBWCOOB",
    id: "SUI04",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "4X70AQQLSZ",
  },
  {
    name: "SUI05 composition",
    code: "6ZIVTFS750",
    id: "SUI05",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "UFGIOHCK75",
  },
  {
    name: "SUI06DX composition",
    code: "DNKXGNG4CC",
    id: "SUI06DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "AO0HCY15W2",
  },
  {
    name: "SUI06SX composition",
    code: "CX51A8CS41",
    id: "SUI06SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "BWT1BZ89YW",
  },
  {
    name: "SUI07DX composition",
    code: "3FH8W3QO0L",
    id: "SUI07DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "N2RX0PPXF7",
  },
  {
    name: "SUI07SX composition",
    code: "OFC1CSSYET",
    id: "SUI07SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "MD49B4FPTF",
  },
  {
    name: "SUI08DX composition",
    code: "GN1GXLMLFL",
    id: "SUI08DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "1AW0NEQOY8",
  },
  {
    name: "SUI08SX composition",
    code: "ILFBLY56VE",
    id: "SUI08SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "051YHCDWW6",
  },
  {
    name: "SUI09DX composition",
    code: "BL7N375H95",
    id: "SUI09DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "DNKYHCNTZ1",
  },
  {
    name: "SUI09SX composition",
    code: "4UFROIQ2KB",
    id: "SUI09SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "P12YOMK80X",
  },
  {
    name: "SUI10DX composition",
    code: "MG8C7N4D5P",
    id: "SUI10DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "JE6JRLFBT9",
  },
  {
    name: "SUI10SX composition",
    code: "S22Z3U98ES",
    id: "SUI10SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "R479PQP1GH",
  },
  {
    name: "SUI11DX composition ",
    code: "Y2IHWRLQO1",
    id: "SUI11DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "W79EE2QARK",
  },
  {
    name: "SUI11SX composition ",
    code: "IC77NY47C4",
    id: "SUI11SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "5HZ1DN8QET",
  },
  {
    name: "SUI12DX composition ",
    code: "JMG2PQ8U1N",
    id: "SUI12DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "NDFGVNY24G",
  },
  {
    name: "SUI12SX composotion",
    code: "34PC5ODCMA",
    id: "SUI12SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "AD18FMHFSF",
  },
  {
    name: "SUI13DX composition",
    code: "AXL3LON3JD",
    id: "SUI13DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "0RAMJM2JB8",
  },
  {
    name: "SUI13SX composition",
    code: "6Q4HBBLKW7",
    id: "SUI13SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "NMPG2F1CMW",
  },
  {
    name: "SUI14DX composition",
    code: "2EBQF9ZUI7",
    id: "SUI14DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "FZXOPSOWT1",
  },
  {
    name: "SUI14SX composition",
    code: "YJ5UUS4UPK",
    id: "SUI14SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "TWA2KQCY7K",
  },
  {
    name: "SUI15DX composition",
    code: "98E1Q3MIVM",
    id: "SUI15DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "MIRFP0PRET",
  },
  {
    name: "SUI15SX composition",
    code: "2WF4OV6A7B",
    id: "SUI15SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "JZ2NA1JALA",
  },
  {
    name: "SUI16DX composition",
    code: "TGOAG2CXRV",
    id: "SUI16DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "U85ZOMUJBO",
  },
  {
    name: "SUI16SX composition",
    code: "EK71HKC13F",
    id: "SUI16SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "0GHCOD2213",
  },
  {
    name: "SUI17DX composition",
    code: "MKRBYX5WEH",
    id: "SUI17DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "AFHG504QJT",
  },
  {
    name: "SUI17SX composition",
    code: "L8KUS8T30G",
    id: "SUI17SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "KRKS1KW7OZ",
  },
  {
    name: "SUI18DX composition",
    code: "WYNHZXPQ0U",
    id: "SUI18DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "S9J81MY6IQ",
  },
  {
    name: "SUI18SX composition",
    code: "MRVSCV1C5M",
    id: "SUI18SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "LWUT2O57WF",
  },
  {
    name: "SUI19DX composition",
    code: "GD4YJD3NWA",
    id: "SUI19DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "D6FZWWMCWM",
  },
  {
    name: "SUI19SX composition",
    code: "F2B45CFA06",
    id: "SUI19SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "DRVVYW0HHK",
  },
  {
    name: "SUI20DX composition",
    code: "2PN9VJJVZ5",
    id: "SUI20DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "K7SRSXRHYP",
  },
  {
    name: "SUI20SX composition",
    code: "JNUOTBOL9I",
    id: "SUI20SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "562SKIFXEZ",
  },
  {
    name: "4010N pouf 150 / ottoman 150",
    code: "HQ1S6TIYKN",
    id: "4010N",
    hasFabric: true,
    family: "Sushi",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "17PW8CRNYH",
  },
  {
    name: "4011N pouf 200 / ottoman 200",
    code: "74IEQA7B8S",
    id: "4011N",
    hasFabric: true,
    family: "Sushi",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "AOC25YQINO",
  },
  {
    name: "4009N pouf 120 / ottoman 120",
    code: "GB15QWAB2Y",
    id: "4009N",
    hasFabric: true,
    family: "Sushi",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "LJQUBYBWWD",
  },
  {
    name: "4007N pouf 60 / ottoman 60",
    code: "CW9599BEL0",
    id: "4007N",
    hasFabric: true,
    family: "Sushi",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "I1F2F2J1XC",
  },
  {
    name: "4008N pouf 90 / ottoman 90",
    code: "AWVO4ZS4US",
    id: "4008N",
    hasFabric: true,
    family: "Sushi",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "9ZAO7DVRHN",
  },
  {
    name: "4019N pouf curvo 188 chiuso / closed ottoman curved 188",
    code: "TIX5MJPVO4",
    id: "4019N",
    hasFabric: true,
    family: "Sushi",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "T1092DJ4LR",
  },
  {
    name: "4021N pouf curvo 200 aperto / open ottoman curved 200",
    code: "Z8Y6ZTU9CL",
    id: "4021N",
    hasFabric: true,
    family: "Sushi",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "FWSW0MLK1T",
  },
  {
    name: "4020N pouf curvo 90° chiuso-aperto / closed-open ottoman curved 90°",
    code: "0Z2ENUNVOL",
    id: "4020N",
    hasFabric: true,
    family: "Sushi",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "QZHFMSP50M",
  },
  {
    name: "4018N pouf curvo 90° chiuso-aperto / closed-open ottoman curved 90°",
    code: "BEK1MNHZ3Z",
    id: "4018N",
    hasFabric: true,
    family: "Sushi",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "YGE1K89IH7",
  },
  {
    name: "SWALE POLTRONA ALTA BASE RIVESTITA",
    code: "FON517GGJY",
    id: "1152",
    hasFabric: true,
    family: "Swale",
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "XJFFXA2M4K",
  },
  {
    name: "1150 poltrona alta con gambe / high armchair with legs",
    code: "5A2GCSNIQ9",
    id: "1150",
    hasFabric: true,
    family: "Swale",
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "2VM8RTQ22J",
  },
  {
    name: "1153 poltrona bassa con base rivestita / low armchair with uph. base",
    code: "GCUS8SQW7G",
    id: "1153",
    hasFabric: true,
    family: "Swale",
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "AW5NOXK5NY",
  },
  {
    name: "1151 poltrona bassa con gambe / low armchair with legs",
    code: "G6GQ4S2THC",
    id: "1151",
    hasFabric: true,
    family: "Swale",
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "JZ2JVW8CH3",
  },
  {
    name: "1400 sedia / chair",
    code: "UGSYZT2GH7",
    id: "1400",
    hasFabric: true,
    hasWood: true,
    customCollection: ["Jaak"],
    customMaterial: ["METAL PITCH BLACK", "METAL AVORIO"],
    family: "Taco",
    projectCode: "FPMBKCWQ26",
  },
  {
    name: "9073N_steel divano 2 posti / 2 seater sofa",
    code: "WG153LKCYF",
    id: "9073N",
    hasFabric: true,
    family: "Tailor",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL RAL 9005",
    ],
    projectCode: "LJC6LK4N5C",
  },
  {
    name: "4074N_wood divano 2 posti major / 2 seater sofa major",
    code: "HBB7OF0HNY",
    id: "4074N",
    hasFabric: true,
    family: "Tailor",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL RAL 9005",
    ],
    projectCode: "I54JWAOXYQ",
  },
  {
    name: "9074N_steel divano 2 posti major / 2 seater sofa major",
    code: "S62R8WW9MT",
    id: "9074N",
    hasFabric: true,
    family: "Tailor",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL RAL 9005",
    ],
    projectCode: "B5ADG5LG7L",
  },
  {
    name: "4071N_wood divano 2 posti small / small 2 seater sofa",
    code: "0EOSIM2L2E",
    id: "4071N",
    hasFabric: true,
    family: "Tailor",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL RAL 9005",
    ],
    projectCode: "5NPTFRPHL8",
  },
  {
    name: "9071N_steel divano 2 posti small / small 2 seater sofa",
    code: "ZYN5IVLEQE",
    id: "9071N",
    hasFabric: true,
    family: "Tailor",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL RAL 9005",
    ],
    projectCode: "RU675DKDEK",
  },
  {
    name: "4073N_wood divano 2 posti / 2 seater sofa",
    code: "KGK5QEPZ06",
    id: "4073N",
    hasFabric: true,
    family: "Tailor",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL RAL 9005",
    ],
    projectCode: "XUC0OL7D66",
  },
  {
    name: "4075N_wood divano 3 posti / 3 seater sofa",
    code: "ZD35RPUBK1",
    id: "4075N",
    hasFabric: true,
    family: "Tailor",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL RAL 9005",
    ],
    projectCode: "QR94UV2Y7Y",
  },
  {
    name: "9075N_steel divano 3 posti / 3 seater sofa",
    code: "XUZR5GWOWV",
    id: "9075N",
    hasFabric: true,
    family: "Tailor",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL RAL 9005",
    ],
    projectCode: "HX938CFO4U",
  },
  {
    name: "4076N_wood divano 3 posti major / 3 seater sofa major",
    code: "IUKKDKLSNU",
    id: "4076N",
    hasFabric: true,
    family: "Tailor",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL RAL 9005",
    ],
    projectCode: "Q793OGBNVL",
  },
  {
    name: "9076N_steel divano 3 posti major / 3 seater sofa major",
    code: "37CZZLVLF4",
    id: "9076N",
    hasFabric: true,
    family: "Tailor",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL RAL 9005",
    ],
    projectCode: "ZRVETL79Z9",
  },
  {
    name: "4072N_wood poltrona / armchair",
    code: "65H83ZNL73",
    id: "4072N",
    hasFabric: true,
    family: "Tailor",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL RAL 9005",
    ],
    projectCode: "MZRJYQC4TM",
  },
  {
    name: "9072N_steel poltrona / armchair",
    code: "ZNL3AOD0JO",
    id: "9072N",
    hasFabric: true,
    family: "Tailor",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL RAL 9005",
    ],
    projectCode: "ZUWNEAQE17",
  },
  {
    name: "4070N_wood poltrona small",
    code: "9170PO5J7R",
    id: "4070N",
    hasFabric: true,
    family: "Tailor",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL RAL 9005",
    ],
    projectCode: "MSD21VDH1B",
  },
  {
    name: "9070N_steel poltrona small / small armchair",
    code: "M5NDTGI6UW",
    id: "9070N",
    hasFabric: true,
    family: "Tailor",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL RAL 9005",
    ],
    projectCode: "3YY1USSRWG",
  },
  {
    name: "TAKIMI SOFA",
    code: "QKDAKEUT9A",
    hasFabric: true,
    family: "Takimi",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9016", "METAL RAL 9005"],
    projectCode: "",
  },
  {
    name: "TIMO POLTRONA",
    code: "WDFLRHHNW2",
    hasFabric: true,
    family: "Timo",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL AVORIO",
      "METAL PITCH BLACK",
    ],
    projectCode: "",
  },
  {
    name: "1361_wood divano small / small sofa",
    code: "1MBRW5NC1N",
    id: "1361",
    hasFabric: true,
    family: "Timo",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL AVORIO",
      "METAL PITCH BLACK",
    ],
    projectCode: "91R339HAG5",
  },
  {
    name: "1357_metal divano small / small sofa",
    code: "NTJFS4XYG4",
    id: "1357",
    hasFabric: true,
    family: "Timo",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL AVORIO",
      "METAL PITCH BLACK",
    ],
    projectCode: "UG9TQJRJF8",
  },
  {
    name: "1363_wood divano lounge / lounge sofa",
    code: "RFHPJGWI3E",
    id: "1363",
    hasFabric: true,
    family: "Timo",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL AVORIO",
      "METAL PITCH BLACK",
    ],
    projectCode: "OZMJ3OY4SK",
  },
  {
    name: "1359_metal divano lounge / lounge sofa",
    code: "N8D21PB4FE",
    id: "1359",
    hasFabric: true,
    family: "Timo",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL AVORIO",
      "METAL PITCH BLACK",
    ],
    projectCode: "33GAIEH89W",
  },
  {
    name: "1365 poltrona con base rivestita/ armchair with uph. base",
    code: "TZUATH8NXM",
    id: "1365",
    hasFabric: true,
    family: "Timo",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL AVORIO",
      "METAL PITCH BLACK",
    ],
    projectCode: "ZL57LSZHGJ",
  },
  {
    name: "1362_wood poltrona lounge/ lounge armchair",
    code: "F2KUPLEQIV",
    id: "1362",
    hasFabric: true,
    family: "Timo",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL AVORIO",
      "METAL PITCH BLACK",
    ],
    projectCode: "N9K71Q3009",
  },
  {
    name: "1358_metal poltrona lounge/ lounge armchair",
    code: "YPG3GVJU8T",
    id: "1358",
    hasFabric: true,
    family: "Timo",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL AVORIO",
      "METAL PITCH BLACK",
    ],
    projectCode: "MI0ONTICUO",
  },
  {
    name: "1356N_metal poltrona small girevole/ small swivel armchair",
    code: "YZO6XAYKUR",
    id: "1356N",
    hasFabric: true,
    family: "Timo",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL AVORIO",
      "METAL PITCH BLACK",
    ],
    projectCode: "IY5IFMHX3T",
  },
  {
    name: "1360_wood poltrona small / small armchair",
    code: "VCN8BYCNX1",
    id: "1360",
    hasFabric: true,
    family: "Timo",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL AVORIO",
      "METAL PITCH BLACK",
    ],
    projectCode: "WRI0P5SF38",
  },
  {
    name: "1355_metal poltrona small / small armchair",
    code: "K4VKI345O9",
    id: "1355",
    hasFabric: true,
    family: "Timo",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL AVORIO",
      "METAL PITCH BLACK",
    ],
    projectCode: "RXCDYFO4WQ",
  },
  {
    name: "8770_D30 tavolino / coffee table",
    code: "J4WU6V0E8W",
    id: "8770_D30_TURI",
    hasFabric: false,
    family: "Turi Turi",
    hasWood: true,
    hasPiano: true,
    customPiano: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "5R5ZC805P7",
  },
  {
    name: "8771_D35 tavolino / coffee table",
    code: "BKLWRVI7XG",
    id: "8771_D35_TURI",
    hasFabric: false,
    family: "Turi Turi",
    hasWood: true,
    hasPiano: true,
    customPiano: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "F3Y9RXPQ17",
  },
  {
    name: "8772_D40 tavolino / coffee table",
    code: "M3KRU7TG21",
    id: "8772_D40_TURI",
    hasFabric: false,
    family: "Turi Turi",
    hasWood: true,
    hasPiano: true,
    customPiano: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "PJIKTB4KW1",
  },
  {
    name: "8773_D50 tavolino / coffee table",
    code: "1I87GTAYGX",
    id: "8773_D50_TURI",
    hasFabric: false,
    family: "Turi Turi",
    hasWood: true,
    hasPiano: true,
    customPiano: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "K3JWM0KJMM",
  },
  {
    name: "8774_D70 tavolino / coffee table",
    code: "AW6SSEI8K1",
    id: "8774_D70_TURI",
    hasFabric: false,
    family: "Turi Turi",
    hasWood: true,
    hasPiano: true,
    customPiano: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "74XBKPZIXL",
  },
  {
    name: "1238N divano 2 posti basso / low 2 seater sofa",
    code: "YAFOFHW2LE",
    id: "1238N",
    family: "Twingo",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINIO LUCIDO", "ALLUMINO OPACO", "METAL RAL 9005"],
    projectCode: "0G4MSOOI9X",
  },
  {
    name: "1235NN divano 2 posti / 2 seater sofa",
    code: "64BGMTP701",
    id: "1235NN",
    family: "Twingo",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINIO LUCIDO", "ALLUMINO OPACO", "METAL RAL 9005"],
    projectCode: "WCNSS2GF22",
  },
  {
    name: "1237N poltrona bassa / low armchair",
    code: "K97ZRXIXVZ",
    id: "1237N",
    family: "Twingo",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINIO LUCIDO", "ALLUMINO OPACO", "METAL RAL 9005"],
    projectCode: "VUBVUKWUAY",
  },
  {
    name: "1234NN poltrona / armchair",
    code: "J1484K4PLG",
    id: "1234NN",
    family: "Twingo",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINIO LUCIDO", "ALLUMINO OPACO", "METAL RAL 9005"],
    projectCode: "W23VGXKIBD",
  },
  {
    name: "1239N_ pouf basso / low ottoman",
    code: "7PKTO3CZ99",
    id: "1239N",
    family: "Twingo",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINIO LUCIDO", "ALLUMINO OPACO", "METAL RAL 9005"],
    projectCode: "UDI937J75M",
  },
  {
    name: "1236NN_steel pouf / ottoman",
    code: "OB0MHW1YTB",
    id: "1236NN",
    family: "Twingo",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINIO LUCIDO", "ALLUMINO OPACO", "METAL RAL 9005"],
    projectCode: "4EZYDY3FX2",
  },
  {
    name: "1105_60x40 tavolino / coffee table",
    code: "DB6JIPL08L",
    id: "1105",
    family: "U",
    hasFabric: false,
    hasPiano: true,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    customPiano: ["ROVERE SPAZZOLATO NATURALE", "ROVERE SPAZZOLATO GRAFITE"],
    projectCode: "KG3QTH8F8K",
  },
  {
    name: "1106_75x75 tavolino / coffee table",
    code: "MWX6RW7DOJ",
    id: "1106",
    family: "U",
    hasFabric: false,
    hasPiano: true,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    customPiano: ["ROVERE SPAZZOLATO NATURALE", "ROVERE SPAZZOLATO GRAFITE"],
    projectCode: "PERT5SPD9D",
  },
  {
    name: "1104_90x40 tavolino / coffee table",
    code: "ESYTQ84WUX",
    id: "1104",
    family: "U",
    hasFabric: false,
    hasPiano: true,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    customPiano: ["ROVERE SPAZZOLATO NATURALE", "ROVERE SPAZZOLATO GRAFITE"],
    projectCode: "SHW6E8J2RX",
  },
  {
    name: "1107_D50 tavolino / coffee table",
    code: "XTYGKZBUSP",
    id: "1107",
    family: "U",
    hasFabric: false,
    hasPiano: true,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    customPiano: ["ROVERE SPAZZOLATO NATURALE", "ROVERE SPAZZOLATO GRAFITE"],
    projectCode: "0X4AWUCDBC",
  },
  {
    name: "1101_60x40 tavolino / coffee table",
    code: "2RPMGVL9FV",
    id: "1101",
    family: "U",
    hasFabric: false,
    hasPiano: true,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    customPiano: ["ROVERE SPAZZOLATO NATURALE", "ROVERE SPAZZOLATO GRAFITE"],
    projectCode: "6G13NUTTC8",
  },
  {
    name: "1102_75x75 tavolino / coffee table",
    code: "RNMA2EJWK4",
    id: "1102",
    family: "U",
    hasFabric: false,
    hasPiano: true,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    customPiano: ["ROVERE SPAZZOLATO NATURALE", "ROVERE SPAZZOLATO GRAFITE"],
    projectCode: "VE6I5HX4HS",
  },
  {
    name: "1100_90x40 tavolino / coffee table",
    code: "2K83NDS81L",
    id: "1100",
    family: "U",
    hasFabric: false,
    hasPiano: true,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    customPiano: ["ROVERE SPAZZOLATO NATURALE", "ROVERE SPAZZOLATO GRAFITE"],
    projectCode: "2TOQCHO4HJ",
  },
  {
    name: "1103_D50 tavolino / coffee table",
    code: "27B3WMPWXO",
    id: "1103",
    family: "U",
    hasFabric: false,
    hasPiano: true,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    customPiano: ["ROVERE SPAZZOLATO NATURALE", "ROVERE SPAZZOLATO GRAFITE"],
    projectCode: "PNBXWPYFMA",
  },
  {
    name: "8501_45x45 tavolino / coffee table",
    code: "3FVOJLLYI8",
    id: "8501_45X45_UP",
    family: "Up",
    hasFabric: false,
    hasWood: true,
    hasPiano: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005", "METAL RAL 9016"],
    customPiano: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO WENGE",
      "WOOD RAL 9016",
      "WOOD RAL 9005",
      "MARMO CALACATTA",
      "MARMO MARQUINA",
      "MARMO CARRARA",
    ],
    projectCode: "1IHHA5EZJX",
  },
  {
    name: "8502_65x45 tavolino / coffee table",
    code: "DTHMSFC1HZ",
    id: "8502_65X45_UP",
    family: "Up",
    hasFabric: false,
    hasWood: true,
    hasPiano: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005", "METAL RAL 9016"],
    customPiano: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO WENGE",
      "WOOD RAL 9016",
      "WOOD RAL 9005",
      "MARMO CALACATTA",
      "MARMO MARQUINA",
      "MARMO CARRARA",
    ],
    projectCode: "MW7GO4FBHT",
  },
  {
    name: "8506_90x90 tavolino / coffee table",
    code: "FS7P21FDYF",
    id: "8506_90X90_UP",
    family: "Up",
    hasFabric: false,
    hasWood: true,
    hasPiano: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005", "METAL RAL 9016"],
    customPiano: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO WENGE",
      "WOOD RAL 9016",
      "WOOD RAL 9005",
      "MARMO CALACATTA",
      "MARMO MARQUINA",
      "MARMO CARRARA",
    ],
    projectCode: "WUI2O74N6K",
  },
  {
    name: "8512_D65 tavolino / coffee table",
    code: "QKWHDXOINI",
    id: "8512_D65_UP",
    family: "Up",
    hasFabric: false,
    hasWood: true,
    hasPiano: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005", "METAL RAL 9016"],
    customPiano: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO WENGE",
      "WOOD RAL 9016",
      "WOOD RAL 9005",
      "MARMO CALACATTA",
      "MARMO MARQUINA",
      "MARMO CARRARA",
    ],
    projectCode: "R4HVHVQMCT",
  },
  {
    name: "8510_D80 tavolino / coffee table ",
    code: "2A74BCSZN4",
    id: "8510_D80_UP",
    family: "Up",
    hasFabric: false,
    hasWood: true,
    hasPiano: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005", "METAL RAL 9016"],
    customPiano: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO WENGE",
      "WOOD RAL 9016",
      "WOOD RAL 9005",
      "MARMO CALACATTA",
      "MARMO MARQUINA",
      "MARMO CARRARA",
    ],
    projectCode: "8BVQON2XLU",
  },
  {
    name: "3402/B80 divano 2 posti h.80 / 2 seater sofa h.80",
    id: "3402/B80",
    code: "QB97A0ZQQZ",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "TQ1DK7RLE3",
  },
  {
    name: "3503/B110 divano 3 posti h.110 / 3 seater sofa h.110",
    id: "3503/B110",
    code: "SOQ22JIK7H",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "0JYASW720U",
  },
  {
    name: "3403/B80 divano 3 posti h.80 / 3 seater sofa h.80",
    id: "3403/B80",
    code: "ZCMEZNOGEY",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "VRDV40H6XF",
  },
  {
    name: "3504/B110 divano 3 posti major h.110 / 3 seater sofa major h.110",
    id: "3504/B110",
    code: "HWUDROAG4R",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "Z3HDQOVHHQ",
  },
  {
    name: "3404/B80 divano 3 posti major h.80 / 3 seater sofa major h.80",
    id: "3404/B80",
    code: "IRY47QCI7N",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "L9W293FAK4",
  },
  {
    name: "3417/B80DX dormeuse 165 dx h.80 / right dormeuse 165 h.80",
    id: "3417/B80DX",
    code: "4V3LY5PZ3B",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "8XOO1U8B4F",
  },
  {
    name: "3419/B80SX dormeuse 165 sx h.80 / left dormeuse 165 h.80",
    id: "3419/B80SX",
    code: "GOKNPZ35D1",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "MH7JDF37YJ",
  },
  {
    name: "3500/B110 poltrona con piedi h.110 / armchair with feet h.110 ",
    id: "3500/B110",
    code: "Q5NYQJAA6H",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "OZPYCFGNUO",
  },
  {
    name: "3501/B110N poltrona girevole h.110 / swivel armchair h.110",
    id: "3501/B110N",
    code: "0RQ3IEDF9L",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "QLX1XDCWUR",
  },
  {
    name: "3592 pouf 150x56 / ottoman 150x56",
    id: "3592",
    code: "3ABCPRHYI2",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "3RSSH28UUQ",
  },
  {
    name: "3306/A sgabello basso con piedi / low stool with feet",
    id: "3306/A",
    code: "W0S04FC3KL",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "I1R0XMALDN",
  },
  {
    name: "3502/B110 divano 2 posti h.110 / 2 seater sofa h.110",
    code: "RZAF1Q2RBW",
    id: "3502/B110",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "TY5J5I4XQT",
  },
  {
    name: "3517/B110DX dormeuse 170 dx h.110 / right dormeuse 170 h.110",
    code: "S3YF1Q0UKN",
    id: "3517/B110DX",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "U4GUK68MGR",
  },
  {
    name: "3519/B110SX dormeuse 170 sx h.110 / left dormeuse 170 h.110",
    code: "BR7YL2F2PI",
    id: "3519/B110SX",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "K89SGFU50G",
  },
  {
    name: "3418/B80DX dormeuse 195 dx h.80 / right dormeuse 195 h.80",
    code: "2A598J7P2P",
    id: "3418/B80DX",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "JG9VT243R0",
  },
  {
    name: "3420/B80SX dormeuse 195 sx h.80 / left dormeuse 195 h.80",
    code: "7S2UWSF69U",
    id: "3420/B80SX",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "6IM09VDNJA",
  },
  {
    name: "3518/B110DX dormeuse 200 dx h.110 / right dormeuse 200 h.110",
    code: "P4AXT7S6J6",
    id: "3518/B110DX",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "I5ILJY9SZA",
  },
  {
    name: "3520/B110SX dormeuse 200 sx h.110 / left dormeuse 200 h.110",
    code: "YRHMOOFMVC",
    id: "3520/B110SX",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "56C00JU1HF",
  },
  {
    name: "3400/B80 poltrona con piedi h.80 / armchair with feet h.80",
    code: "XU2VKT5L0G",
    id: "3400/B80",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "AA3J22ITI1",
  },
  {
    name: "3401/B80N poltrona girevole h.80 / swivel armchair h.80",
    code: "DQZZW6RWMW",
    id: "3401/B80N",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "6QF3BCR80F",
  },
  {
    name: "3593 pouf 100x100 / ottoman 100x100",
    code: "8Z93O10TIP",
    id: "3593",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "DCDTGAXGTI",
  },
  {
    name: "3591 pouf 100x56 / ottoman 100x56",
    code: "28Z3GANXRG",
    id: "3591",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "9VS94EPY5J",
  },
  {
    name: "3590 pouf 56x56 / ottoman 56x56",
    code: "9585OBNS8J",
    id: "3590",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "7H3IA49RYB",
  },
  {
    name: "1012 pouf parallel / parallel ottoman",
    code: "XJWHTDQ3CR",
    id: "1012",
    hasFabric: true,
    hasWood: true,
    family: "Waves",
    customMaterial: ["METAL RAL 7021"],
    projectCode: "M5B6CDF9WO",
  },
  {
    name: "1010 pouf diamond / diamond ottoman",
    code: "YFG1UO85LH",
    id: "1010",
    hasFabric: true,
    hasWood: true,
    family: "Waves",
    customMaterial: ["METAL RAL 7021"],
    projectCode: "7HUBPECHXZ",
  },
  {
    name: "1016 dormeuse leaf / leaf dormeuse",
    code: "NGUPO2MYTO",
    id: "1016",
    hasFabric: true,
    hasWood: true,
    family: "Waves",
    customMaterial: ["METAL RAL 7021"],
    projectCode: "VE81ULJJVD",
  },
  {
    name: "1011 pouf diamond con schienale / diamond ottoman with backrest",
    code: "Z0WKRZA35M",
    id: "1011",
    hasFabric: true,
    hasWood: true,
    family: "Waves",
    customMaterial: ["METAL RAL 7021"],
    projectCode: "B5XJDA9628",
  },
  {
    name: "1017 pouf hexagon / hexagon ottoman",
    code: "2FCLX93FQ1",
    id: "1017",
    hasFabric: true,
    hasWood: true,
    family: "Waves",
    customMaterial: ["METAL RAL 7021"],
    projectCode: "HA5VG4Y2Q4",
  },
  {
    name: "1013DX pouf parallel con schienale dx / parallel ottoman with right backrest",
    code: "NIJQKV6ECO",
    id: "1013DX",
    hasFabric: true,
    hasWood: true,
    family: "Waves",
    customMaterial: ["METAL RAL 7021"],
    projectCode: "RK3N050KH7",
  },
  {
    name: "1014 pouf trapezium / trapezium ottoman",
    code: "LR6OWK68VE",
    id: "1014",
    hasFabric: true,
    hasWood: true,
    family: "Waves",
    customMaterial: ["METAL RAL 7021"],
    projectCode: "TXYS6VP3MS",
  },
  {
    name: "1015 pouf trapezium con schienale",
    code: "G7VTI4LO70",
    id: "1015",
    hasFabric: true,
    hasWood: true,
    family: "Waves",
    customMaterial: ["METAL RAL 7021"],
    projectCode: "OVFXJ33JHO",
  },
  {
    name: "8451N divano 3 posti / 3 seater sofa",
    code: "2VKWQZXPR9",
    id: "8451N",
    hasFabric: true,
    hasWood: true,
    family: "Why not",
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "SF85VQWE1H",
  },
  {
    name: "8452N divano 4 posti / 4 seater sofa",
    code: "5Q395CKGOC",
    id: "8452N",
    hasFabric: true,
    hasWood: true,
    family: "Why not",
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "0TKD7JMWD2",
  },
  {
    name: "8466N pouf 190 / ottoman 190",
    code: "RMZZ82Q1ZE",
    id: "8466N",
    hasFabric: true,
    hasWood: true,
    family: "Why not",
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "1ZVQCFIN1U",
  },
  {
    name: "8467N pouf curvo R180 / curved ottoman R180",
    code: "U3LSMODTRH",
    id: "8467N",
    hasFabric: true,
    hasWood: true,
    family: "Why not",
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "EWJWOG6MFC",
  },
  {
    name: "8468N pouf curvo R395 / curved ottoman R395",
    code: "OO02O5OD06",
    id: "8468N",
    hasFabric: true,
    hasWood: true,
    family: "Why not",
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "J3RI2B1UU0",
  },
  {
    name: "8459N angolo aperto R180 / open corner R180",
    code: "LJ6JSG0AQW",
    id: "8459N",
    hasFabric: true,
    hasWood: true,
    family: "Why not",
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "9181KMJI9M",
  },
  {
    name: "8458N angolo chiuso R180 / closed corner R180",
    code: "ZTAE7JDR2U",
    id: "8458N",
    hasFabric: true,
    hasWood: true,
    family: "Why not",
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "49Q56PN7OZ",
  },
  {
    name: "8862 pouf D180 / ottoman D180",
    code: "6HVMQ8HMXM",
    hasFabric: true,
    hasWood: false,
    family: "Windmill",
    customCollection: ["Hallingdal 65", "Fame"],
    customMaterial: [],
    projectCode: "HVA0A5YQ9Z",
  },
];

export default products;
