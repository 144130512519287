// config.js

import materiali from "./materials-stuff";

// This will store our fetched materials
export let material = materiali;

// Function to fetch materials
/* const fetchMaterials = () => {
  fetch('https://management.ayea.it/api/materials')
    .then(response => response.json())
    .then(data => {
      material = data.data; // Update the exported materials
      console.log(data.data);
    })
    .catch(error => console.error('Error fetching materials:', error));
};

fetchMaterials(); */




export const defaultRivestimento = {
  slug: '',
  name: '',
  thumbnail: '',
};

// If found, set defaultStruttura to the material object
export const defaultStruttura =  {
  slug: '',
  name: '',
  thumbnail: '',
};

export let defaultFinitura =  {
  slug: '',
  name: '',
  thumbnail: '',
};

export let configuration = {
  rivestimento: defaultRivestimento.slug,
};
export let appliedConfiguration = `${configuration.rivestimento}`;


export function switchConfiguration(configurationName, type) {
  let configurableMaterial = null
  if (type === 'imbottito') {
    configuration.rivestimento = configurationName;
    configurableMaterial = 'imbottito'
    appliedConfiguration = `${configuration.rivestimento.slug}`
  } else if (type === 'struttura') {
    configuration.struttura = configurationName;
    configurableMaterial = 'struttura'
    appliedConfiguration = `${configuration.struttura.slug}`

  } else if (type === 'finitura') {
    configuration.finitura = configurationName;
    configurableMaterial = 'finitura'
    appliedConfiguration = `${configuration.finitura.slug}`

  } else if (/^imbottito_/.test(type)) {
    configuration.rivestimento = configurationName;
    configurableMaterial = type
    appliedConfiguration = `${configuration.rivestimento.slug}`
  }

    // Update appliedConfiguration with configuration values


  emViewers['emersyaIframe'].setMaterials({
    materials: [{ materialVariation: appliedConfiguration, configurableMaterial }],
  })
}

