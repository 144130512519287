import WOOD_01 from "../assets/catWood/Faggio_Naturale.jpg";
import WOOD_02 from "../assets/catWood/Faggio_Naturale_Tinta-noce.jpg";
import WOOD_03 from "../assets/catWood/Frassino_Naturale.jpg";
import WOOD_04 from "../assets/catWood/Frassino_Grafite.jpg";
import WOOD_05 from "../assets/catWood/Frassino_Naturale_Wenge.jpg";
import WOOD_06 from "../assets/catWood/Frassino_Tinta_Noce.jpg";
import WOOD_07 from "../assets/catWood/Rovere_Spazzolato_Bianco.jpg";
import WOOD_08 from "../assets/catWood/Rovere_Spazzolato_Wenge.jpg";
import WOOD_09 from "../assets/catWood/Rovere_Spazzolato_Grafite.jpg";
import WOOD_10 from "../assets/catWood/Rovere_Spazzolato_Naturale.jpg";
import WOOD_11 from "../assets/catWood/wood-ral-9016.jpg";
import AVORIO from "../assets/catWood/AVORIO.jpg";
import AZZURRO_FIORDALISO from "../assets/catWood/AZZURRO-FIORDALISO.jpg";
import BRONZO from "../assets/catWood/BRONZO.jpg";
import CIOCCOLATO from "../assets/catWood/CIOCCOLATO.jpg";
import FANGO from "../assets/catWood/FANGO.jpg";
import OTTONE from "../assets/catWood/OTTONE.jpg";
import PITCH_BLACK from "../assets/catWood/PITCH-BLACK.jpg";
import PRUGNA from "../assets/catWood/PRUGNA.jpg";
import RAL_303_RED from "../assets/catWood/RAL-303-RED.jpg";
import RAL_5014_BLUE from "../assets/catWood/RAL-5014-BLUE.jpg";
import RAL_7021_GRIGIO_SCURO from "../assets/catWood/RAL-7021-GRIGIO-SCURO.jpg";
import RAL_9006_BLACK from "../assets/catWood/RAL-9006-BLACK.jpg";
import RAL_9016_WHITE from "../assets/catWood/RAL-9016-WHITE.jpg";
import RAME from "../assets/catWood/RAME.jpg";
import TE_VERDE from "../assets/catWood/TE-VERDE.jpg";
import ALLUMINIO_LUCIDO from "../assets/catWood/alluminio-lucido.jpg";
import ALLUMINIO_OPACO from "../assets/catWood/alluminio-opaco.jpg";
import CROMATO_LUCIDO from "../assets/catWood/cromato-lucido.jpg";

import placeholder from "../assets/placeholder.jpg";
import MARMO_MARQUINA from "../assets/catWood/marmo-marquina.jpg";
import MARMO_CALACATTA from "../assets/catWood/marmo-calacatta.jpg";
import MARMO_CARRARA from "../assets/catWood/marmo-carrara.jpg";
import VETRO_TRASPARENTE from "../assets/catWood/vetro-trasparente.jpg";
import VETRO_FUME from "../assets/catWood/vetro-fume.jpg";

const wood = [
  {
    category: "Struttura",
    type: "struttura",
    collection: "Wood",
    items: [
      { name: "FAGGIO NATURALE", slug: "FAGGIO NATURALE", thumbnail: WOOD_01 },
      {
        name: "FAGGIO TINTA NOCE",
        slug: "FAGGIO TINTA NOCE",
        thumbnail: WOOD_02,
      },
      {
        name: "FRASSINO NATURALE",
        slug: "FRASSINO NATURALE",
        thumbnail: WOOD_03,
      },
      {
        name: "FRASSINO TINTA GRAFITE",
        slug: "FRASSINO TINTA GRAFITE",
        thumbnail: WOOD_04,
      },
      {
        name: "FRASSINO TINTA WENGE",
        slug: "FRASSINO TINTA WENGE",
        thumbnail: WOOD_05,
      },
      {
        name: "FRASSINO TINTA NOCE",
        slug: "FRASSINO TINTA NOCE",
        thumbnail: WOOD_06,
      },
      {
        name: "ROVERE SPAZZOLATO BIANCO",
        slug: "ROVERE SPAZZOLATO BIANCO",
        thumbnail: WOOD_07,
      },
      {
        name: "ROVERE SPAZZOLATO WENGE",
        slug: "ROVERE SPAZZOLATO WENGE",
        thumbnail: WOOD_08,
      },
      {
        name: "ROVERE SPAZZOLATO GRAFITE",
        slug: "ROVERE SPAZZOLATO GRAFITE",
        thumbnail: WOOD_09,
      },
      {
        name: "ROVERE SPAZZOLATO NATURALE",
        slug: "ROVERE SPAZZOLATO NATURALE",
        thumbnail: WOOD_10,
      },
      { name: "RAL 9005", slug: "WOOD RAL 9005", thumbnail: RAL_9006_BLACK },
      { name: " RAL 9005", slug: " RAL 9005", thumbnail: RAL_9006_BLACK },
      { name: "RAL 9016", slug: "WOOD RAL 9016", thumbnail: WOOD_11 },
    ],
  },
  {
    category: "Struttura",
    type: "struttura",
    collection: "Metal",
    items: [
      {
        name: "ALLUMINIO LUCIDO",
        slug: "ALLUMINIO LUCIDO",
        thumbnail: ALLUMINIO_LUCIDO,
      },
      {
        name: "ALLUMINIO OPACO",
        slug: "ALLUMINO OPACO",
        thumbnail: ALLUMINIO_OPACO,
      },
      {
        name: "CROMATO LUCIDO",
        slug: "CROMATO LUCIDO",
        thumbnail: CROMATO_LUCIDO,
      },
      { name: "METAL AVORIO", slug: "METAL AVORIO", thumbnail: AVORIO },
      {
        name: "METAL AZZURRO FIORDALISO",
        slug: "METAL AZZURRO FIORDALISO",
        thumbnail: AZZURRO_FIORDALISO,
      },
      { name: "METAL BRONZO", slug: "METAL BRONZO", thumbnail: BRONZO },
      {
        name: "METAL CIOCCOLATO",
        slug: "METAL CIOCCOLATO",
        thumbnail: CIOCCOLATO,
      },
      { name: "METAL FANGO", slug: "METAL FANGO", thumbnail: FANGO },
      { name: "METAL OTTONE", slug: "METAL OTTONE", thumbnail: OTTONE },
      {
        name: "METAL PITCH BLACK",
        slug: "METAL PITCH BLACK",
        thumbnail: PITCH_BLACK,
      },
      { name: "METAL PRUGNA", slug: "METAL PRUGNA", thumbnail: PRUGNA },
      {
        name: "METAL RAL 3003",
        slug: "METAL RAL 3003",
        thumbnail: RAL_303_RED,
      },
      {
        name: "METAL RAL 5014",
        slug: "METAL RAL 5014",
        thumbnail: RAL_5014_BLUE,
      },
      {
        name: "METAL RAL 7021",
        slug: "METAL RAL 7021",
        thumbnail: RAL_7021_GRIGIO_SCURO,
      },
      {
        name: "METAL RAL 9005",
        slug: "METAL RAL 9005",
        thumbnail: RAL_9006_BLACK,
      },
      {
        name: "METAL RAL 9016",
        slug: "METAL RAL 9016",
        thumbnail: RAL_9016_WHITE,
      },
      { name: "METAL RAME", slug: "METAL RAME", thumbnail: RAME },
      { name: "METAL TE VERDE", slug: "METAL TE VERDE", thumbnail: TE_VERDE },
    ],
  },
  {
    category: "Struttura",
    type: "struttura",
    collection: "Marmo",
    items: [
      {
        name: "MARMO MARQUINA",
        slug: "MARMO MARQUINA",
        thumbnail: MARMO_MARQUINA,
      },
      {
        name: "MARMO CALACATTA",
        slug: "MARMO CALACATTA",
        thumbnail: MARMO_CALACATTA,
      },
      {
        name: "MARMO CARRARA",
        slug: "MARMO CARRARA",
        thumbnail: MARMO_CARRARA,
      },
      {
        name: "VETRO TRASPARENTE",
        slug: "VETRO TRASPARENTE",
        thumbnail: VETRO_TRASPARENTE,
      },
      { name: "VETRO FUME", slug: "VETRO FUME", thumbnail: VETRO_FUME },
    ],
  },
];

export default wood;
