import "./index.css";
import App from "./App.js";
import JsonEditorPage from "./JsonEditorPage";
import { createRoot } from "react-dom/client";
import Planner from "./Planner.js";

const root = createRoot(document.querySelector("#ayea-player"));

const renderPage = () => {
  const hash = window.location.hash || "#/";

  if (hash === "#/json") {
    root.render(<JsonEditorPage />);
  } else if (hash === "#/planner") {
    root.render(<Planner />);
  } else {
    const productId = document.getElementById("ayea-product-id").value;
    root.render(<App productId={productId} />);
  }
};

// Render la pagina iniziale
renderPage();

// Aggiungi un listener per i cambiamenti nell'hash
window.addEventListener("hashchange", renderPage);
