import React, { useState, useEffect } from 'react';
import { JsonEditor } from 'json-edit-react';

function JsonEditorPage() {
  const [jsonData, setJsonData] = useState([]);
  const [selectedFamily, setSelectedFamily] = useState('');
  const [families, setFamilies] = useState([]);

  useEffect(() => {
    fetch('https://www.enotorrerecords.com/saveLoadJson.php')
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          // Filter out null or undefined products
          const validData = data.filter(product => product != null);

          setJsonData(validData);

          // Extract unique families and sort them alphabetically
          const uniqueFamilies = Array.from(
            new Set(validData.map(item => item.family).filter(Boolean))
          ).sort((a, b) => a.localeCompare(b));

          setFamilies(uniqueFamilies);

          if (uniqueFamilies.length > 0) {
            setSelectedFamily(uniqueFamilies[0]); // Set the first family as default
          }
        } else {
          console.error('Data received is not an array:', data);
        }
      })
      .catch(error => console.error('Error loading data:', error));
  }, []);

  const handleJsonChange = (updatedFamilyData) => {
    // Ensure each product has the 'family' property set
    const updatedDataWithFamily = updatedFamilyData
      .filter(product => product != null)
      .map(product => {
        if (!product.family) {
          return { ...product, family: selectedFamily };
        }
        return product;
      });

    // Update the jsonData state by replacing the data for the selected family
    setJsonData(prevJsonData => {
      // Remove old data for the selected family
      const otherFamiliesData = prevJsonData.filter(
        product => product != null && product.family !== selectedFamily
      );
      // Combine other families' data with updated data for the selected family
      return [...otherFamiliesData, ...updatedDataWithFamily];
    });
  };

  const handleSave = () => {
    fetch('https://www.enotorrerecords.com/saveLoadJson.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonData),
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 'success') {
          alert('Data saved successfully!');
        } else {
          alert('Failed to save data: ' + data.error);
        }
      })
      .catch(error => {
        console.error('Error saving data:', error);
        alert('An error occurred while saving data.');
      });
  };

  // Handle cases where product or product.family might be null or undefined
  const filteredData = jsonData.filter(
    product => product != null && product.family === selectedFamily
  );

  return (
    <div className="ay-mx-auto ay-px-10 ay-flex">
      <div className="ay-w-2/5">
        <button
          onClick={handleSave}
          className="ay-bg-blue-500 ay-hover:bg-blue-700 ay-text-white ay-font-bold ay-py-2 ay-px-4 ay-rounded ay-mt-4"
        >
          Salva
        </button>
        <h1 className="ay-text-xl ay-font-bold ay-my-4">
          Editor JSON dei Prodotti - Famiglia: {selectedFamily}
        </h1>
        <JsonEditor
          className="ay-w-[100%]"
          data={filteredData}
          setData={handleJsonChange}
        />
      </div>
      <div className="ay-w-3/5 ay-pl-4">
        <h2 className="ay-font-bold ay-text-lg ay-mb-4">Famiglie di Prodotti</h2>
        <ul className="ay-grid ay-grid-cols-3 ay-gap-2">
          {families.map(family => (
            <li
              key={family}
              className={`ay-p-2 ay-mb-1 ay-col-span-1 ay-rounded ay-cursor-pointer ${
                selectedFamily === family ? 'ay-bg-blue-500 ay-text-white' : 'ay-bg-gray-100'
              }`}
              onClick={() => setSelectedFamily(family)}
            >
              {family}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default JsonEditorPage;
